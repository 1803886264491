import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PlatformLocation } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Tracker } from '@nbkc/tracker-angular';
import { AuthService } from '@services/auth.service';
import { CacheService } from '@services/cache.service';
import { ConfigService } from '@services/config.service';
import { UserService } from '@services/user.service';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './auth.interceptor';
import { ErrorComponent } from './components/error/error.component';
import { LoginComponent } from './components/login/login.component';
import { MainLoaderComponent } from './components/main-loader/main-loader.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { ConfirmModalComponent } from './components/landing-page/confirm-modal/confirm-modal.component';
import { ErrorInterceptor } from './error.interceptor';
import { MaintenanceInterceptor } from './maintenance.interceptor';
import { SharedModule } from './shared/shared.module';
import { startupFactory } from './startup/app.startup';
import { LogOutComponent } from './components/log-out/log-out.component';
import { PreApprovalFormComponent } from './components/borrower-portal/pre-approval-letter-form/pre-approval-letter-form.component';
import { LeftMenuComponent } from './components/borrower-portal/left-menu/left-menu.component';
import { SharedComponentsModule } from 'projects/shared-components/src/lib/shared-components.module';
import { ESignComponent } from './components/borrower-portal/e-sign/e-sign.component';
import { PaymentFormComponent } from 'src/app/components/borrower-portal/payment-form/payment-form.component';
import { DocumentUploadComponent } from './components/borrower-portal/document-upload/document-upload.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DocumentUploadProgressBarComponent } from './components/borrower-portal/document-upload/document-upload-progress-bar/document-upload-progress-bar.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { BorrowerPortalComponent } from './components/application/borrower-portal.component';


@NgModule({
	declarations: [
		AppComponent,
		MainLoaderComponent,
		ErrorComponent,
		BorrowerPortalComponent,
		ConfirmModalComponent,
		MaintenanceComponent,
		LoginComponent,
		LogOutComponent,
		PreApprovalFormComponent,
		LeftMenuComponent,
		ESignComponent,
        PaymentFormComponent,
		DocumentUploadComponent,
  		DocumentUploadProgressBarComponent,
    	LandingPageComponent
	],
	imports: [
		BrowserModule,
		HttpClientModule,
		AppRoutingModule,
		SharedModule,
		BrowserAnimationsModule,
		SharedComponentsModule,
		MatProgressBarModule
	],
	providers: [
		CookieService,
		{
			provide: APP_INITIALIZER,
			useFactory: startupFactory,
			deps: [AuthService, UserService, ConfigService, CacheService, Tracker, PlatformLocation],
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MaintenanceInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorInterceptor,
			multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}

<div class="lib-card-container">
    <div class="lib-card">
        <div *ngIf="hasCornerBanner">
            <lib-card-right-corner-banner-component></lib-card-right-corner-banner-component>
        </div>
        <lib-card-header-component>
            <div class="lib-card-heading">
                <lib-icon-component class="lib-icon" [iconClass]="headerIcon"></lib-icon-component>
                <div class="lib-card-heading-title-container">
                    <div class="lib-card-title">{{headerTitle}}</div>
                    <div class="lib-card-subtitle" *ngIf="headerSubTitle">{{headerSubTitle}}</div>
                    <div class="lib-card-subtitle" *ngIf="headerSecondSubTitle">{{headerSecondSubTitle}}</div>

                </div>
                <lib-card-right-corner-icon-component *ngIf="headerRightIcon">
                    {{headerRightIcon}}    
                        <img alt="apy percentage" src="/assets/img/apy.svg">
                </lib-card-right-corner-icon-component>
            </div>
        </lib-card-header-component>
        <lib-card-content>
            <p class="lib-card-content-body">{{contentBody}}</p>
            <ng-content select="[card-content]"></ng-content>
        </lib-card-content>
        <lib-card-footer>
            <ng-content select="[card-footer-button]"></ng-content>
        </lib-card-footer>
    </div>
</div>

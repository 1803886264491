export class ProfileApi {
	public FirstName: string;
	public LastName: string;
	public SecondaryPhone: string;
	public PrimaryPhone: string;
	public EmailAddress: string;
	public ContentType: string;
	public Nmls: string;
	public Photo: string;
	public Roles: string[];
	public LoanApplicationUrl: string;
	public Attributes: any;
}

/* eslint-disable max-len */

import _ from 'lodash';
import { ContactApi } from '@models/api/contact';
import { DeclarationsProgress } from '@models/form/progress/declarations.progress.model';
import { ContactForm } from '@models/form/contact-form.model';
import { CurrentApplicationForm } from '@models/form/loan-application-form.model';
import { AdapterBase } from '@utilities/adapter-base';
import { LoanApplicationApi } from '@models/api/loan-application';
import { BorrowerApi } from '@models/api/borrower';
import { IdentifiersApi } from '@models/api/identifiers';
import { ApplicationPurposes, SectionNames } from '@constants';
import { LoanFormToLoanDetailsApiAdapter } from './loan-form-to-loan-details-api.adapter';
import { LoanFormToSubjectPropertyApiAdapter } from './loan-form-to-subject-property-api.adapter';
import { EmploymentFormToEmployerApiAdapter } from './employment-form-to-employer-api.adapter';
import { IncomeSourceFormToIncomeSourceApiAdapter } from './income-source-form-to-income-source-api.adapter';
import { ResidenceFormToResidenceApiAdapter } from './residence-form-to-residence-api.adapter';
import { BorrowerDeclarations2009FormToDeclarationsApiAdapter } from './borrower-declarations2009-form-to-declarations-api.adapter';
import { BorrowerDeclarationsFormToLiabilityApiAdapter } from './borrower-declarations-form-to-liability-api.adapter';
import { BorrowerFormToBorrowerApiAdapter } from './borrower-form-to-borrower-api.adapter';
import { BorrowerDemographicFormToDemographicApiCollectionAdapter } from './borrower-demographic-form-to-demographic-api-collection.adapter';
import { AccountFormToAssetApiAdapter } from './account-form-to-asset-api.adapter';
import { RealEstateFormToReoPropertyApiAdapter } from './real-estate-form-to-reo-property-api.adapter';
import { LinkedAccountFormCollectionToLinkedAccountApiAdapter } from './linked-account-form-collection-to-linked-account-api.adapter';
import { SupplementApplicationRequest } from '@models/request/supplement-application-request.model';
import { BorrowerDeclarations2020FormToDeclarationsApiAdapter } from './borrower-declarations2020-form-to-declarations-api.adapter';
import { BorrowerDeclarations2020Form } from '@models/form/declarations/borrower-declarations-2020.model';
import { BorrowerDeclarations2009Form } from '@models/form/declarations/borrower-declarations-2009.model';
import { IBorrowerDeclarationsForm } from '@models/form/declarations/borrower-declarations';
import { LoanOfficerSummaryApi } from '@models/api/loan-officer-summary.model';
import { LoanSectionProgress } from '@models/form/progress/loan-section.progress.model';

export class CurrentApplicationFormToSupplementApplicationRequestAdapter extends AdapterBase<CurrentApplicationForm, SupplementApplicationRequest> {
	private _formData: CurrentApplicationForm;
	private _loanAppApi: LoanApplicationApi;
	private _loanFormToLoanDetailsApiAdapter: LoanFormToLoanDetailsApiAdapter = new LoanFormToLoanDetailsApiAdapter();
	private _loanFormToSubjectPropertyApiAdapter: LoanFormToSubjectPropertyApiAdapter = new LoanFormToSubjectPropertyApiAdapter();
	private _employmentFormToEmployerApiAdapter: EmploymentFormToEmployerApiAdapter = new EmploymentFormToEmployerApiAdapter();
	private _incomeSourceFormToIncomeSourceApiAdapter: IncomeSourceFormToIncomeSourceApiAdapter = new IncomeSourceFormToIncomeSourceApiAdapter();
	private _residenceFormToResidenceApiAdapter: ResidenceFormToResidenceApiAdapter = new ResidenceFormToResidenceApiAdapter();
	private _declarations2009FormToDeclarationsApiAdapter: BorrowerDeclarations2009FormToDeclarationsApiAdapter = new BorrowerDeclarations2009FormToDeclarationsApiAdapter();
	private _declarations2020FormToDeclarationsApiAdapter: BorrowerDeclarations2020FormToDeclarationsApiAdapter = new BorrowerDeclarations2020FormToDeclarationsApiAdapter();
	private _declarationsFormToLiabilityApiAdapter: BorrowerDeclarationsFormToLiabilityApiAdapter = new BorrowerDeclarationsFormToLiabilityApiAdapter();
	private _borrowerFormToBorrowerApiAdapter: BorrowerFormToBorrowerApiAdapter = new BorrowerFormToBorrowerApiAdapter();
	private _borrowerDemographicFormToDemographicApiCollectionAdapter: BorrowerDemographicFormToDemographicApiCollectionAdapter = new BorrowerDemographicFormToDemographicApiCollectionAdapter();
	private _accountFormToAssetApiAdapter: AccountFormToAssetApiAdapter = new AccountFormToAssetApiAdapter();
	private _realEstateFormToReoPropertyApiAdapter: RealEstateFormToReoPropertyApiAdapter = new RealEstateFormToReoPropertyApiAdapter();
	private _linkedAccountFormCollectionToLinkedAccountApiAdapter: LinkedAccountFormCollectionToLinkedAccountApiAdapter = new LinkedAccountFormCollectionToLinkedAccountApiAdapter();

	public adapt(formData: CurrentApplicationForm): SupplementApplicationRequest {
		this._formData = _.cloneDeep(formData);
		const loanSectionProgress: LoanSectionProgress = this._formData.progress.sections[SectionNames.loan] as LoanSectionProgress;
		loanSectionProgress.hasPreferredLoanOfficer = this._formData.loan.hasPreferredLoanOfficer;
		loanSectionProgress.hasRealEstateAgent = this._formData.loan.hasRealEstateAgent;
		this._formData.progress.sections[SectionNames.loan] = loanSectionProgress;

		this._loanAppApi = this._adaptApplication();
		this._loanAppApi.Identifiers = this._adaptIdentifiers();
		this._loanAppApi.RequestedLoanDetails = this._loanFormToLoanDetailsApiAdapter.adapt(this._formData.loan);
		this._loanAppApi.SubjectProperty = this._loanFormToSubjectPropertyApiAdapter.adapt(this._formData.loan);
		this._loanAppApi.Borrowers = this._adaptBorrowers();
		this._loanAppApi.Assets = this._accountFormToAssetApiAdapter.adaptCollection(this._formData.accounts);
		this._loanAppApi.LinkedAccounts = this._linkedAccountFormCollectionToLinkedAccountApiAdapter.adaptCollection(this._formData.linkedAccounts);
		this._loanAppApi.ReoProperties = this._realEstateFormToReoPropertyApiAdapter.adaptCollection(this._formData.realEstate);
		this._loanAppApi.LoanOfficer = this.adaptLoanOfficer();
		this._loanAppApi.Source = this._formData.source.getSource();

		const contacts = this._adaptContacts();
		if (contacts && contacts.length > 0) {
			this._loanAppApi.ApplicationContacts = contacts;
		}
		const request = new SupplementApplicationRequest();
		request.LoanApplicationId = this._formData.id;
		request.UpdatedApplication = this._loanAppApi;
		

		return request;
	}

	private _adaptApplication(): LoanApplicationApi {
		const myLoanApplication = new LoanApplicationApi();
		myLoanApplication.CreatedDate = this._formData.created;
		myLoanApplication.ApplicationProgress = JSON.stringify(this._formData.progress);
		myLoanApplication.LastModifiedDate = this._formData.modified;
		myLoanApplication.HasVaEligibleBorrower = this._formData.hasVaEligibleBorrower;
		return myLoanApplication;
	}

	private _adaptIdentifiers(): IdentifiersApi {
		const identifiers = new IdentifiersApi();
		identifiers.ApplicationId = this._formData.id;
		identifiers.UrlToken = this._formData.urlToken;
		identifiers.PlatformRefId = this._formData.platformRefId;
		identifiers.LoanGuid = this._formData.loanGuid;
		return identifiers;
	}

	private _adaptBorrowers(): BorrowerApi[] {
		return this._borrowerFormToBorrowerApiAdapter.adaptCollectionWith(this._formData.borrowers, (source, dest) => {
			dest.Employers = this._employmentFormToEmployerApiAdapter.adaptCollection(
				_.filter(this._formData.employment, (x) => x.borrowerIndex === source.index)
			);
			dest.NonEmploymentIncome = this._incomeSourceFormToIncomeSourceApiAdapter.adaptCollection(
				_.filter(this._formData.income, (x) => x.borrowerIndex === source.index)
			);
			dest.Residences = this._residenceFormToResidenceApiAdapter.adaptCollection(
				_.filter(this._formData.residences, (x) => _.includes(x.borrowerIndexes, source.index))
			);
			if (this._formData.urlaVersion === '2020') {
				dest.Declarations = this._declarations2020FormToDeclarationsApiAdapter.adapt(
					_.find(this._formData.declarations.borrowerDeclarations as BorrowerDeclarations2020Form[], (x) => x.borrowerIndex === source.index)
				);
			} else {
				dest.Declarations = this._declarations2009FormToDeclarationsApiAdapter.adapt(
					_.find(this._formData.declarations.borrowerDeclarations as BorrowerDeclarations2009Form[], (x) => x.borrowerIndex === source.index)
				);
			}
			dest.Liabilities = this._declarationsFormToLiabilityApiAdapter.adapt(
				_.find(this._formData.declarations.borrowerDeclarations as IBorrowerDeclarationsForm[], (x) => x.borrowerIndex === source.index)
			);
			dest.Demographics = this._borrowerDemographicFormToDemographicApiCollectionAdapter.adapt(
				_.find(this._formData.demographics.borrowerDemographics, (x) => x.borrowerIndex === source.index)
			);
		});
	}

	private _adaptContacts(): ContactApi[] {
		const contacts: ContactApi[] = [];
		const realtorContact: ContactForm = this._formData.loan.realEstateAgent;
		if (realtorContact && this._formData.loan.purpose !== ApplicationPurposes.refinance) {
			const tmpContact = new ContactApi();
			tmpContact.ContactName = realtorContact.name;
			tmpContact.ContactPhone = realtorContact.phone;
			tmpContact.ContactEmail = realtorContact.email;
			tmpContact.ContactType = 'BuyersAgent';
			contacts.push(tmpContact);
		}
		const insuranceContact: ContactForm = this._formData.declarations.insuranceCompany;
		if (insuranceContact && insuranceContact.hasContact) {
			const tmpContact = new ContactApi();
			tmpContact.BusinessName = insuranceContact.name;
			tmpContact.ContactPhone = insuranceContact.phone;
			tmpContact.ContactType = 'HazardInsurance';
			contacts.push(tmpContact);
		} else if (insuranceContact && insuranceContact.hasContact === false) {
			const declarationsProgress = new DeclarationsProgress(
				this._formData.progress.sections[SectionNames.declarations]
			);
			if (!_.includes(declarationsProgress.answeredContact, 'HazardInsurance')) {
				declarationsProgress.answeredContact.push('HazardInsurance');
			}
			this._formData.progress.sections[SectionNames.declarations] = declarationsProgress;
		}
		return contacts;
	}

private adaptLoanOfficer(): LoanOfficerSummaryApi {
		if (!this._formData.loan.preferredLoanOfficer) {
			return null;
		}
		const loanOfficerSummaryApi: LoanOfficerSummaryApi = new LoanOfficerSummaryApi();
		loanOfficerSummaryApi.UserId = this._formData.loan.preferredLoanOfficer.UserId;
		loanOfficerSummaryApi.DisplayName = this._formData.loan.preferredLoanOfficer.DisplayName;
		return loanOfficerSummaryApi;
	}
}

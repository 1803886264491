import { AdapterBase } from '@utilities/adapter-base';
import { OtherIncomeForm } from '@models/form/other-income.model';
import { IncomeSourceApi } from '@models/api/income-source';
import numeral from 'numeral';

export class IncomeSourceFormToIncomeSourceApiAdapter extends AdapterBase<OtherIncomeForm, IncomeSourceApi> {
	adapt(source: OtherIncomeForm): IncomeSourceApi {
		if (source && source.type) {
			const dest = new IncomeSourceApi();
			dest.MonthlyValue = numeral(source.monthlyAmount).value();
			dest.Type = source.type;
			dest.Description = source.description;
			return dest;
		}

		return null;
	}
}

import $ from 'jquery';

export class CompatibilityFixes {
	public static isOldBrowser(): boolean {
		const ua = window.navigator.userAgent;
		const isIE10Minus = ua.indexOf('MSIE ');
		const isIE11 = ua.indexOf('Trident/');
		return isIE10Minus > 0 || isIE11 > 0;
	}

	public static ie11ScreenRedrawFix(selector: string, delayInMs: number = 3000): void {
		if (this.isOldBrowser()) {
			setTimeout(() => {
				const $element = $(selector);
				const currentVisibleState = $element.css('opacity');
				$element.css('opacity', Number(currentVisibleState) - .01);
				requestAnimationFrame(() => {
					requestAnimationFrame(() => {
						$element.css('opacity', Number(currentVisibleState));
					});
				});
			}, delayInMs);
		}
	}
}

import { AdapterBase, AdapterOptions } from '@utilities/adapter-base';
import { LoanForm } from '@models/form/loan.model';
import { LoanDetailsApi } from '@models/api/loan-details';
import numeral from 'numeral';
import { ApplicationPurposes } from '../../constants/application-purposes';

export class LoanFormToLoanDetailsApiAdapter extends AdapterBase<LoanForm, LoanDetailsApi> {
	adapt(source: LoanForm, options?: AdapterOptions): LoanDetailsApi {
		const myLoan = new LoanDetailsApi();
		myLoan.LoanAmount = numeral(source.amount).value();
		if (source.purpose !== ApplicationPurposes.refinance && source.purpose !== ApplicationPurposes.heloc) {
			myLoan.DownPayment = numeral(source.downPaymentAmount).value();
		}
		myLoan.LoanPurpose = (source.purpose === ApplicationPurposes.refinance && source.cashOut) ? ApplicationPurposes.cashOutRefinance : source.purpose;
		return myLoan;
	}
}

export const DemographicsCheckOptions = {
	male: {
		id: 2,
		display: 'Male',
		section: 'sex',
		compositeId: {
			displayName: 'Male',
			category: 'Gender'
		},
		freeForm: false
	},
	female: {
		id: 1,
		display: 'Female',
		section: 'sex',
		compositeId: {
			displayName: 'Female',
			category: 'Gender'
		},
		freeForm: false
	},
	noGender: {
		id: 3,
		display: 'I do not wish to provide gender',
		section: 'sex',
		compositeId: {
			displayName: 'I do not wish to provide this information',
			category: 'Gender'
		},
		freeForm: false
	},
	hispanicOrLatino: {
		id: 5,
		display: 'Hispanic or Latino',
		section: 'ethnicity',
		compositeId: {
			displayName: 'Hispanic or Latino',
			category: 'Ethnicity'
		},
		freeForm: false
	},
	mexican: {
		id: 9,
		display: 'Mexican',
		section: 'ethnicity',
		compositeId: {
			displayName: 'Mexican',
			category: 'EthnicityOrigin'
		},
		freeForm: false
	},
	puertoRican: {
		id: 10,
		display: 'Puerto Rican',
		section: 'ethnicity',
		compositeId: {
			displayName: 'Puerto Rican',
			category: 'EthnicityOrigin'
		},
		freeForm: false
	},
	cuban: {
		id: 11,
		display: 'Cuban',
		section: 'ethnicity',
		compositeId: {
			displayName: 'Cuban',
			category: 'EthnicityOrigin'
		},
		freeForm: false
	},
	otherHispanicOrLatino: {
		id: 12,
		display: 'Other Hispanic or Latino',
		section: 'ethnicity',
		compositeId: {
			displayName: 'Hispanic or Other Latino',
			category: 'EthnicityOrigin'
		},
		freeForm: false
	},
	otherEthnicityFreeResponse: {
		id: 32,
		display: null,
		section: 'ethnicity',
		compositeId: {
			displayName: 'Other Hispanic or Latino Origin',
			category: 'EthnicityOrigin'
		},
		freeForm: true
	},
	notHispanicOrLatino: {
		id: 6,
		display: 'Not Hispanic or Latino',
		section: 'ethnicity',
		compositeId: {
			displayName: 'Not Hispanic or Latino',
			category: 'Ethnicity'
		},
		freeForm: false
	},
	noEthnicity: {
		id: 7,
		display: 'I do not wish to provide ethnicity',
		section: 'ethnicity',
		compositeId: {
			displayName: 'I do not wish to provide this information',
			category: 'Ethnicity'
		},
		freeForm: false
	},
	americanIndianOrAlaskanNative: {
		id: 13,
		display: 'American Indian or Alaskan Native',
		section: 'race',
		compositeId: {
			displayName: 'American Indian or Alaskan Native',
			category: 'Race'
		},
		freeForm: false
	},
	otherAmericanIndianOrAlaskanNativeFreeResponse: {
		id: 14,
		display: null,
		section: 'race',
		compositeId: {
			displayName: 'Enrolled or principal tribe',
			category: 'Race'
		},
		freeForm: true
	},
	asian: {
		id: 15,
		display: 'Asian',
		section: 'race',
		compositeId: {
			displayName: 'Asian',
			category: 'Race'
		},
		freeForm: false
	},
	asianIndian: {
		id: 21,
		display: 'Asian Indian',
		section: 'race',
		compositeId: {
			displayName: 'Asian Indian',
			category: 'RaceDesignation'
		},
		freeForm: false
	},
	chinese: {
		id: 22,
		display: 'Chinese',
		section: 'race',
		compositeId: {
			displayName: 'Chinese',
			category: 'RaceDesignation'
		},
		freeForm: false
	},
	filipino: {
		id: 23,
		display: 'Filipino',
		section: 'race',
		compositeId: {
			displayName: 'Filipino',
			category: 'RaceDesignation'
		},
		freeForm: false
	},
	japanese: {
		id: 24,
		display: 'Japanese',
		section: 'race',
		compositeId: {
			displayName: 'Japanese',
			category: 'RaceDesignation'
		},
		freeForm: false
	},
	korean: {
		id: 25,
		display: 'Korean',
		section: 'race',
		compositeId: {
			displayName: 'Korean',
			category: 'RaceDesignation'
		},
		freeForm: false
	},
	vietnamese: {
		id: 26,
		display: 'Vietnamese',
		section: 'race',
		compositeId: {
			displayName: 'Vietnamese',
			category: 'RaceDesignation'
		},
		freeForm: false
	},
	otherAsian: {
		id: 27,
		display: 'Other Asian',
		section: 'race',
		compositeId: {
			displayName: 'Other Asian',
			category: 'RaceDesignation'
		},
		freeForm: false
	},
	otherAsianFreeResponse: {
		id: 34,
		display: null,
		section: 'race',
		compositeId: {
			displayName: 'Other Asian Race',
			category: 'RaceDesignation'
		},
		freeForm: true
	},
	blackOrAfricanAmerican: {
		id: 16,
		display: 'Black or African American',
		section: 'race',
		compositeId: {
			displayName: 'Black or African American',
			category: 'Race'
		},
		freeForm: false
	},
	nativeHawaiianOrOtherPacificIslander: {
		id: 17,
		display: 'Native Hawaiian or other Pacific Islander',
		section: 'race',
		compositeId: {
			displayName: 'Native Hawaiian or other Pacific Islander',
			category: 'Race'
		},
		freeForm: false
	},
	nativeHawaiian: {
		id: 28,
		display: 'Native Hawaiian',
		section: 'race',
		compositeId: {
			displayName: 'Native Hawaiian',
			category: 'RaceDesignation'
		},
		freeForm: false
	},
	guamanianOrChamorro: {
		id: 29,
		display: 'Guamanian or Chamorro',
		section: 'race',
		compositeId: {
			displayName: 'Guamanian or Chamorro',
			category: 'RaceDesignation'
		},
		freeForm: false
	},
	samoan: {
		id: 30,
		display: 'Samoan',
		section: 'race',
		compositeId: {
			displayName: 'Samoan',
			category: 'RaceDesignation'
		},
		freeForm: false
	},
	otherPacificIslander: {
		id: 31,
		display: 'Other Pacific Islander',
		section: 'race',
		compositeId: {
			displayName: 'Other Pacific Islander',
			category: 'RaceDesignation'
		},
		freeForm: false
	},
	otherPacificIslanderFreeResponse: {
		id: 33,
		display: null,
		section: 'race',
		compositeId: {
			displayName: 'Other Pacific Islander Race',
			category: 'RaceDesignation'
		},
		freeForm: true
	},
	white: {
		id: 18,
		display: 'White',
		section: 'race',
		compositeId: {
			displayName: 'White',
			category: 'Race'
		},
		freeForm: false
	},
	noRace: {
		id: 19,
		display: 'I do not wish to provide race',
		section: 'race',
		compositeId: {
			displayName: 'I do not wish to provide this information',
			category: 'Race'
		},
		freeForm: false
	}
};

import { AdapterBase, AdapterOptions } from '@utilities/adapter-base';
import { IncomeSourceApi } from '@models/api/income-source';
import { OtherIncomeForm } from '@models/form/other-income.model';
import { NumberHelper } from '@utilities/helpers';

export class IncomeSourceApiToOtherIncomeFormAdapter extends AdapterBase<IncomeSourceApi, OtherIncomeForm> {
	public adapt(source: IncomeSourceApi, options: CurrentAdapterOptions): OtherIncomeForm {
		const myIncome = new OtherIncomeForm(options.borrowerIndex);
		myIncome.type = source.Type;
		myIncome.monthlyAmount = NumberHelper.numberToString(source.MonthlyValue);
		myIncome.description = source.Description;
		return myIncome;
	}
}

interface CurrentAdapterOptions extends AdapterOptions {
	borrowerIndex: number;
}

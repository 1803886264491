import { DemographicAnswer } from '@models/form/demographics/demographic-helpers/demographic-answer.model';
import { Validate, Validators } from '@nbkc/validation';
import { DemographicSection } from './demographic-section.model';

export class DemographicsSex extends DemographicSection {
	@Validate(Validators.required())
	public male: DemographicAnswer<boolean> = new DemographicAnswer(false);
	@Validate(Validators.required())
	public female: DemographicAnswer<boolean> = new DemographicAnswer(false);
	@Validate(Validators.required())
	public noGender: DemographicAnswer<boolean> = new DemographicAnswer(false);

	constructor() {
		super();
	}
}

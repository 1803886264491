<header id="main-header" class="main app-header">
	<a class="logo" (click)="navHome()">
		<img class="mobile-site-logo" alt="nbkc logo" src="/assets/img/nbkc-logo.svg">
	</a>
	<div class="user-circle-container" *ngIf='!isOnProfile'>
		<button id="mainMenuButton" aria-label="main menu" (superClick)="toggleUserCircle()" class="user-circle-menu">
			<i class="fal fa-user-circle"></i>
		</button>
	</div>
</header>

<nav class="main-menu main-menu-desktop horizontal-collapse d-none d-sm-block" *ngIf="!userCircleCollapsed"
     (click)="userCircleCollapser()" aria-labelledby="mainMenuButton">
	<ul class="profile-menu">
		<li *ngIf="authService.loggedIn" class="list-section-header">
			{{(applicationService.currentApplication.borrowers[0].firstName || "") + (applicationService.currentApplication.borrowers[0].firstName && applicationService.currentApplication.borrowers[0].lastName ? " " : "") +
	(applicationService.currentApplication.borrowers[0].lastName || "")}}
		</li>
		<li *ngIf="authService.loggedIn && userService.hasProfile">
			<a id="linkMainMenuManageApplications" (superClick)="onMenuClick('myApplications')">Manage Applications</a>
		</li>
		<li *ngIf="authService.loggedIn && userService.isBorrower === false && userService.isProfileComplete">
			<a id="linkMainMenuNewApplication" (superClick)="onMenuClick('newApplication')">New Application</a>
		</li>
		<li *ngIf="authService.loggedIn">
			<a id="linkMainMenuSignOut" (superClick)="onMenuClick('signOut')">Sign Out</a>
		</li>
		<li *ngIf="!authService.loggedIn">
			<a id="linkMainMenuSignIn" (superClick)="onMenuClick('signIn')">Sign in</a>
		</li>
	</ul>
</nav>
<nav class="main-menu horizontal-collapse d-block d-sm-none"
	[ngClass]="{'all-blue-nav': !isOnLongForm, 'animate': animateUserCircle, 'slide-in': !userCircleCollapsed, 'slide-out': userCircleCollapsed}">
	<div class="menu-item-container">
		<div>
			<div id="linkMainMenuManageApplicationsMobile" class="row main-menu-item blue" (click)="onMenuClick('myApplications')"
				*ngIf="authService.loggedIn && userService.hasProfile">
				<label class="clickable">Manage Applications</label>
			</div>
			<div id="linkMainMenuNewApplicationMobile" class="row main-menu-item blue" (click)="onMenuClick('newApplication')"
				*ngIf="authService.loggedIn && userService.isBorrower === false &&  userService.isProfileComplete">
				<label class="clickable">New Application</label>
			</div>
			<div id="linkMainMenuSignOutMobile" class="row main-menu-item blue" (click)="onMenuClick('signOut')"
				*ngIf="authService.loggedIn">
				<label class="clickable">Sign Out</label>
			</div>
			<div id="linkMainMenuSignInMobile" class="row main-menu-item blue" (click)="onMenuClick('signIn')"
				*ngIf="!authService.loggedIn">
				<label class="clickable">Sign In</label>
			</div>
		</div>
		<div *ngIf="isOnLongForm && authService.loggedIn && sectionService.current?.showNavigation">
			<div class="row main-menu-item"></div>
			<div class="menu-item" *ngFor="let section of navSections">
				<app-mobile-menu-row (click)="navigateSection(section.name)" [sectionName]="section.name"
					[isReviewed]="reviewed"></app-mobile-menu-row>
			</div>
		</div>
		<div id="linkNmls" class="equal-housing-lender-container"
			*ngIf="isOnLongForm && authService.loggedIn && sectionService.current && sectionService.current.showNavigation">
			<img src="/assets/img/equal_housing_logo_hor.svg" class="equal-housing-lender-logo" alt="equal housing lender logo" />
			<div class="equal-housing-lender-text"><i>nbkc bank - NMLS #<u><a
							href="http://nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/409631"
							target="_blank" rel="noopener noreferrer" id="linkNmlsMobile">409631</a></u></i></div>
		</div>
	</div>
</nav>
<div class="screen-overlay d-block d-sm-none" (click)="userCircleCollapsed=true" *ngIf="!userCircleCollapsed"></div>

import { Validatable, Validate, Validators } from '@nbkc/validation';

export class AccountForm extends Validatable {
	@Validate(Validators.required('Please select an account type.')) public assetType: string | null;
	@Validate(Validators.currency('Please enter the account balance.')) public amount: string;
	public gift: boolean;
	@Validate(Validators.required('Please enter a financial institution.')) public name: string;
	public holders: number[];

	constructor(props: any = { }) {
		super();
		this.holders = props.holders || [];
		this.assetType = '';
	}
}

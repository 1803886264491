
export class PortfolioRelationshipForm{
    public role: string; 
    public userId: string; 
    public active: boolean; 
    public firstName : string; 
    public lastName : string; 
    public nmls : string;
    public primaryPhoneNumber : string; 
    public emailAddress : string; 
}

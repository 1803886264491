import { Validatable } from '@nbkc/validation';
import { AddressForm } from './address.model';

export class RealEstateForm extends Validatable {
	public id: string;
	public address: AddressForm;
	public propertyUsageType: string | null;

	constructor(props: any = { }) {
		super();
		this.address = props.address || new AddressForm({ requireCounty: false });
		this.propertyUsageType = '';
	}
}

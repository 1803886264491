<div class="loan-app-form-control">
	<div class="combobox" [ngClass]="{'loan-app-invalid error': showError, 'loan-app-required': required }">
		<input type="text" [(ngModel)]="inputText" (ngModelChange)="getFilteredList()" style="border: none;"
			class="combobox-input" (keyup)="onKeyPress($event)" (blur)="toggleListDisplay(0)"
			(focus)="toggleListDisplay(1)" placeholder="Select one..."
			[disabled]="disabled" aria-label="{accessibilityLabelText}">
	</div>
	<div class="combobox-options" *ngIf="!listHidden">
		<div class="list-item" *ngFor="let item of filteredList; let i = index"
			(mouseout)="handleMouseOut($event)" (mouseover)="handleMouseover($event)" (click)="selectItem(i)">{{item}}
		</div>
	</div>
	<div *ngIf="showError" class="error-text">Invalid Selection</div>
</div>
import { Component, Input } from '@angular/core';
import { NavigationDetails } from '@models/navigation-details.model';
import { NavigationService } from '@services/navigation.service';

@Component({
	selector: 'app-mobile-menu-row',
	templateUrl: './mobile-menu-row.component.html',
	styleUrls: ['./mobile-menu-row.component.scss']
})
export class MobileMenuRowComponent {
	@Input() public sectionName: string;
	@Input() public isReviewed: boolean;

	get navDetails(): NavigationDetails {
		return this.navigationService.getNavigationDetails(this.sectionName, this.isReviewed);
	}

	constructor(public navigationService: NavigationService) {
	}
}

import { ReferralSources } from '@lookups/referral-sources';

export class ReferralSource {
	public sourceType: string | null;
	public otherSource: string | null;

	public getSource(): string {
		if (this.sourceType && this.sourceType.toLowerCase() === 'other') {
			return this.otherSource;
		} else {
			return this.sourceType;
		}
	}

	constructor(source: string) {
		if (source && !ReferralSources.includes(source)) {
			this.sourceType = 'Other';
			this.otherSource = source;
		} else {
			this.sourceType = source;
		}
	}
}

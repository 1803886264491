import { IdDescriptionPair } from './lookups';

export const ApplicationPurposes: IdDescriptionPair[] = [
	{
		id: 'Purchase',
		description: 'Purchase'
	},
	{
		id: 'Refinance',
		description: 'Refinance'
	},
	{
		id: 'PreApproval',
		description: 'Pre-Approval',
	},
	{
		id: 'CashOutRefinance',
		description: 'Cash Out Refinance',
	},
	{
		id: 'HELOC',
		description: 'HELOC',
	}
];

import { PortfolioRequest } from "@models/api/borrower-portal/portfolio-request";
import { AdapterBase } from "@utilities/adapter-base";
import { DocumentUploadBucketType } from "../../constants/borrower-portal/document-upload-bucket-type";
import { PortfolioRequestForm } from "@models/form/borrower-portal/portfolio-request-form.model";

export class PortfolioRequestToPortfolioRequestFormAdapter extends AdapterBase<PortfolioRequest, PortfolioRequestForm> {
	adapt(source: PortfolioRequest): PortfolioRequestForm {

			const dest = new PortfolioRequestForm();
			dest.displayName = source.DisplayName;
			dest.description = source.Description;
			dest.documentation = source.Documentation;
			dest.id = source.Id;
			dest.origination = source.Origination;
			dest.responsibility = source.Responsibility;
			dest.status = source.Status;
			dest.detail = source.Detail;
			dest.reason = source.Reason;
			dest.type = source.Type;
			dest.source = source.Source;
			dest.scopeId = source.Scope.Id;
			dest.scopeType = source.Scope.Type;
			dest.icon = this.getDocumentUploadCardIcon(source.DisplayName)

			return dest;
	}

    private getDocumentUploadCardIcon(requestName: string): string {
		switch(requestName){
			case DocumentUploadBucketType.AdditionalDocs:{
				return "fal fa-file";
			}
			case DocumentUploadBucketType.GreenCardDocs:{
				return "fal fa-passport";
			}
			case DocumentUploadBucketType.DivorceOrChildSupportDocs:{
				return "fal fa-file-contract";
			}
			case DocumentUploadBucketType.ProofOfInsuranceDocs:{
				return "fal fa-house-damage";
			}
			case DocumentUploadBucketType.MortgageStatementDocs:{
				return "fal fa-file-alt";
			}
			case DocumentUploadBucketType.TaxReturnDocs:{
				return "fal fa-file-invoice-dollar"
			}
			default: {
				return "fal fa-file";
			}
		}
	}
}
<div *ngIf="loanOfficer" [ngClass]="{'expanded': appState.loanOfficerCardShown === true }" class="lo-card-container" keyboardFocus (superClick)="toggleLoanOfficer()">
    <div class="lo-card d-none d-sm-block" id="lo-card-desktop">
        <div class="help-loan-office-image"
            [ngStyle]="{'background' : 'url(' + loanOfficer.loanOfficerImageUrl + ') no-repeat top center / cover'}">
        </div>
        <div class="loan-picture-wrap"></div>
        <div class="lo-name-block">
            <label class="lo-name-text" (click)="$event.stopPropagation()">{{loanOfficer.loanOfficerName}}</label>
            <label class="nmls-number-text" id="loCardNmlsLabel" (click)="$event.stopPropagation()"
                *ngIf="loanOfficer.nmlsNum">NMLS: <a id="loCardNmls" href="{{loanOfficer.nmlsLink}}" target="_blank"
                    rel="noopener noreferrer" [clickTracker]="nmlsClick">{{loanOfficer.nmlsNum}}</a></label>
        </div>
        <div *ngIf="loanOfficer.loanOfficerOfficePhone" class="help-loan-officer-info-block">
            <span class="fa fa-phone" aria-hidden="true"></span>
            <label class="help-lo-number-email-text" (click)="$event.stopPropagation()"><a id="loCardOfficePhone"
                    href="tel:{{grammarService.safeNumber(loanOfficer.loanOfficerOfficePhone)}}"
                    [clickTracker]="officePhoneClick">{{loanOfficer.loanOfficerOfficePhone}}</a></label>
            <label class="help-lo-number-email-subtext">Office</label>
        </div>
        <div *ngIf="loanOfficer.loanOfficerMobilePhone" class="help-loan-officer-info-block">
            <span class="fa fa-mobile-alt" aria-hidden="true"></span>
            <label class="help-lo-number-email-text" (click)="$event.stopPropagation()"><a id="loCardMobilePhone"
                    href="tel:{{grammarService.safeNumber(loanOfficer.loanOfficerMobilePhone)}}"
                    [clickTracker]="mobilePhoneClick">{{loanOfficer.loanOfficerMobilePhone}}</a></label>
            <label class="help-lo-number-email-subtext">Mobile</label>
        </div>
        <div *ngIf="loanOfficer.loanOfficerEmail" class="help-loan-officer-info-block">
            <span class="fa fa-envelope" aria-hidden="true"></span>
            <label class="help-lo-number-email-text" (click)="$event.stopPropagation()"><a id="loCardEmail"
                    href="mailto:{{loanOfficer.loanOfficerEmail}}"
                    [clickTracker]="emailClick">{{loanOfficer.loanOfficerEmail}}</a></label>
            <label class="help-lo-number-email-subtext">Email</label>
        </div>
    </div>
    <div class="mobile-lo-container d-block d-sm-none" id="lo-card-mobile">
        <div class="lo-card" (click)="$event.stopPropagation()">
            <div class="help-loan-office-image"
                [ngStyle]="{'background' : 'url(' + loanOfficer.loanOfficerImageUrl + ') no-repeat top center / cover'}">
            </div>
            <div class="loan-picture-wrap"></div>
            <div class="lo-name-block">
                <label class="lo-name-text" (click)="$event.stopPropagation()">{{loanOfficer.loanOfficerName}}</label>
                <label class="nmls-number-text" (click)="$event.stopPropagation()" *ngIf="loanOfficer.nmlsNum">NMLS: <a
                        id="loCardNmlsMobileView" href="{{loanOfficer.nmlsLink}}" target="_blank"
                        rel="noopener noreferrer" [clickTracker]="nmlsClick">{{loanOfficer.nmlsNum}}</a></label>
            </div>
            <div *ngIf="loanOfficer.loanOfficerOfficePhone" class="help-loan-officer-info-block">
                <span class="fa fa-phone" aria-hidden="true"></span>
                <label class="help-lo-number-email-text" (click)="$event.stopPropagation()"><a id="loCardOfficePhoneMobileView"
                        href="tel:{{grammarService.safeNumber(loanOfficer.loanOfficerOfficePhone)}}"
                        [clickTracker]="officePhoneClick">{{loanOfficer.loanOfficerOfficePhone}}</a></label>
                <label class="help-lo-number-email-subtext">Office</label>
            </div>
            <div *ngIf="loanOfficer.loanOfficerMobilePhone" class="help-loan-officer-info-block">
                <span class="fa fa-lg fa-mobile-alt" aria-hidden="true"></span>
                <label class="help-lo-number-email-text" (click)="$event.stopPropagation()"><a id="loCardMobilePhoneMobileView"
                        href="tel:{{grammarService.safeNumber(loanOfficer.loanOfficerMobilePhone)}}"
                        [clickTracker]="mobilePhoneClick">{{loanOfficer.loanOfficerMobilePhone}}</a></label>
                <label class="help-lo-number-email-subtext">Mobile</label>
            </div>
            <div *ngIf="loanOfficer.loanOfficerEmail" class="help-loan-officer-info-block">
                <span class="fa fa-envelope" aria-hidden="true"></span>
                <label class="help-lo-number-email-text" (click)="$event.stopPropagation()"><a id="loCardEmailMobileView"
                        href="mailto:{{loanOfficer.loanOfficerEmail}}"
                        [clickTracker]="emailClick">{{loanOfficer.loanOfficerEmail}}</a></label>
                <label class="help-lo-number-email-subtext">Email</label>
            </div>
        </div>
    </div>
</div>

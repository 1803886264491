import { Validatable, Validate, Validators } from '@nbkc/validation';

export class PreApprovalForm extends Validatable {
	@Validate(Validators.currency('Please enter the sales price.'))
	public salesPrice: string;
	public loanToValue: number;
	public loanAmount: number = 0; 
	public loanId : string; 
	public validationEnabled: boolean = true; // Temp fix

	constructor(props: any = { }) {
		super();
	}
}

import { LoanApplicationApi } from '@models/api/loan-application';
import { Declarations } from '@models/form/declarations.model';
import { Demographics } from '@models/form/demographics/demographics.model';
import { RequiredDocument } from '@models/required-document.model';
import { Validatable, Validate, ValidateIf, Validators } from '@nbkc/validation';
import { AccountForm } from './account.model';
import { BorrowerForm } from './borrower.model';
import { EmploymentForm } from './employment.model';
import { LoanForm } from './loan.model';
import { OtherIncomeForm } from './other-income.model';
import { FormProgress } from './progress/form.progress.model';
import { QualificationResult } from './qualification-result.model';
import { RealEstateForm } from './real-estate.model';
import { ResidenceForm } from './residence.model';
import { LinkedAccountFormCollection } from './linked-account-collection.model';
import { ReferralSource } from '@models/referral-source-model';

export class LoanApplicationForm extends Validatable {
	@Validate(Validators.required())
	public id: string;
	@Validate(Validators.required())
	public accounts: AccountForm[];
	public linkedAccounts: LinkedAccountFormCollection[];
	public applicationStatusId: number | string;
	public applicationPurposeId: number | string | null;
	@Validate(Validators.required())
	public borrowers: BorrowerForm[];
	public businessRules: QualificationResult[];
	public created: string;
	@Validate(Validators.required())
	public declarations: Declarations = new Declarations();
	@Validate(Validators.required())
	public demographics: Demographics = new Demographics();
	public eConsent: boolean;
	@Validate(Validators.required())
	public employment: EmploymentForm[] = [];
	public failedRules: QualificationResult[];
	@ValidateIf('this.income', Validators.required())
	public income: OtherIncomeForm[] = [];
	public isQualifiedForSubmission: boolean;
	@Validate(Validators.required())
	public loan: LoanForm;
	public loanOfficerId: string;
	public loanGuid: string;
	@Validate(Validators.required())
	public modified: string;
	public progress: FormProgress;
	public platformRefId: string;
	@Validate(Validators.required())
	public realEstate: RealEstateForm[];
	@Validate(Validators.required())
	public residences: ResidenceForm[];
	public requiredDocuments: RequiredDocument[];
	public userId: string;
	public urlaVersion: string;
	public urlToken: string;
	public hasVaEligibleBorrower: boolean;
	public validationEnabled: boolean = true; // Temp fix
	public source: ReferralSource;
}

export class CurrentApplicationForm extends LoanApplicationForm {
	public originalApplication: LoanApplicationForm;
	public originalApplicationApi: LoanApplicationApi;
}

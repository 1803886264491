export class AddressApi {
	public AddressLine1: string;
	public AddressLine2: string;
	public County: string;
	public City: string;
	public State: string;
	public PostalCode: string;
	public UnitType: string;
	public UnitNumber: string;
	public CountryCode: string;
	public IsMailingAddress: boolean;
}

<app-main-loader></app-main-loader>
<router-outlet></router-outlet>
<div class="modern-browser-notification"
	*ngIf="appState.isReady && !appState.isLoading && isOldBrowser && !dismissOldBrowser">
	<div class="modern-browser-message-wrap">
		<div class="modern-browser-message">For a better experience, please use one of the latest browsers:</div>
		<a class="browser-recommendation" target="_blank" href="https://www.google.com/chrome/" rel="noopener noreferrer">
			<div class="browser-recommendation-image fab fa-chrome"></div>
			<div class="browser-recommendation-name">Google Chrome</div>
		</a>
		<a class="browser-recommendation" target="_blank" href="https://www.mozilla.org/en-US/firefox/" rel="noopener noreferrer">
			<div class="browser-recommendation-image fab fa-firefox"></div>
			<div class="browser-recommendation-name">Mozilla Firefox</div>
		</a>
		<a class="browser-recommendation" target="_blank" href="https://www.microsoft.com/en-us/windows/microsoft-edge" rel="noopener noreferrer">
			<div class="browser-recommendation-image fab fa-edge"></div>
			<div class="browser-recommendation-name">Microsoft Edge</div>
		</a>
	</div>
	<div class="modern-browser-dismiss" (click)="dismissOldBrowser = true"><span class="fa fa-times"></span></div>
</div>

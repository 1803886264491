export class DemographicsSectionKeyMap {
	public section: string;
	public key: string;
	public freeForm: boolean;

	constructor(section: string, key: string, freeForm: boolean) {
		this.section = section;
		this.key = key;
		this.freeForm = freeForm;
	}
}

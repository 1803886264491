import { AdapterBase, AdapterOptions } from '@utilities/adapter-base';
import { DeclarationsApi } from '@models/api/declarations';
import { BorrowerDeclarations2009Form } from '@models/form/declarations/borrower-declarations-2009.model';

export class DeclarationsApiToBorrowerDeclarations2009FormAdapter extends AdapterBase<DeclarationsApi, BorrowerDeclarations2009Form> {
	public adapt(source: DeclarationsApi, options: CurrentAdapterOptions): BorrowerDeclarations2009Form {
		const dest = new BorrowerDeclarations2009Form(options.borrowerIndex);
		dest.bankruptcy = source.Bankruptcy;
		dest.citizenship = source.CitizenshipResidencyType;
		dest.coSignerOnLoan = source.CoMakerEndorserOfNote;
		dest.delinquentOnFederalDebtOrOtherLoan = source.PresentlyDelinquent;
		dest.foreclosures = source.PriorForeclosure;
		dest.haveAlimonyPayments = source.AlimonyObligation;
		dest.haveChildSupportPayments = source.ChildSupportObligation;
		dest.mortgageForbearance = source.MortgageForbearance;
		dest.obligatedOnLoanForeclosureOrJudgment = source.LoanForeclosureOrJudgment;
		dest.ownershipInterestPastThreeYears = source.PriorPropertyOwnerPastThreeYears;
		dest.outstandingJudgments = source.OutstandingJudgments;
		dest.partOfDownPaymentBorrowed = source.BorrowedDownPayment;
		dest.partyToLawsuit = source.PartyToLawsuit;
		dest.priorPropertyTitleType = source.PriorPropertyTitleType || null;
		dest.priorPropertyUsageType = source.PriorPropertyUsageType || null;

		return dest;
	}
}

interface CurrentAdapterOptions extends AdapterOptions {
	borrowerIndex: number;
}

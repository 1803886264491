import { Validatable, Validate, ValidateIf, Validators } from '@nbkc/validation';
import { IBorrowerDeclarationsForm } from './borrower-declarations';

export class BorrowerDeclarations2009Form extends Validatable implements IBorrowerDeclarationsForm {
	public borrowerIndex: number;
	@Validate(Validators.required()) public bankruptcy: boolean | null;
	@Validate(Validators.required()) public citizenship: string | null;
	@Validate(Validators.required()) public coSignerOnLoan: boolean | null;
	@Validate(Validators.required()) public delinquentOnFederalDebtOrOtherLoan: boolean | null;
	@Validate(Validators.required()) public foreclosures: boolean | null;
	@Validate(Validators.required()) public haveAlimonyPayments: boolean | null;
	@Validate(Validators.required()) public haveChildSupportPayments: boolean | null;
	@Validate(Validators.required()) public mortgageForbearance: boolean | null;
	@Validate(Validators.required()) public obligatedOnLoanForeclosureOrJudgment: boolean | null;
	@Validate(Validators.required()) public outstandingJudgments: boolean | null;
	@Validate(Validators.required()) public ownershipInterestPastThreeYears: boolean | null;
	@Validate(Validators.required()) public partyToLawsuit: boolean | null;

	@ValidateIf('this.haveAlimonyPayments === true', Validators.required())
	public alimonyPayments: number | null;

	@ValidateIf('this.haveChildSupportPayments === true', Validators.required())
	public childSupportPayments: number | null;

	// eslint-disable-next-line max-len
	@ValidateIf(`this.parentRef && this.parentRef.parentRef && this.parentRef.parentRef.loan && this.parentRef.parentRef.loan.purpose !== 'Refinance'`, Validators.required())
	public partOfDownPaymentBorrowed: boolean | null;

	@ValidateIf('this.ownershipInterestPastThreeYears === true', Validators.required())
	public priorPropertyTitleType: string | null;

	@ValidateIf('this.ownershipInterestPastThreeYears === true', Validators.required())
	public priorPropertyUsageType: string | null;

	constructor(borrowerIndex: number) {
		super();
		this.borrowerIndex = borrowerIndex;

		this.alimonyPayments = null;
		this.bankruptcy = null;
		this.childSupportPayments = null;
		this.citizenship = null;
		this.coSignerOnLoan = null;
		this.delinquentOnFederalDebtOrOtherLoan = null;
		this.foreclosures = null;
		this.haveAlimonyPayments = null;
		this.haveChildSupportPayments = null;
		this.obligatedOnLoanForeclosureOrJudgment = null;
		this.outstandingJudgments = null;
		this.ownershipInterestPastThreeYears = null;
		this.partOfDownPaymentBorrowed = null;
		this.partyToLawsuit = null;
		this.priorPropertyTitleType = '';
		this.priorPropertyUsageType = '';
	}
}
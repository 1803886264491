import { Injectable } from '@angular/core';
import { ConfigConstants } from '../../../assets/config/config-constants';

@Injectable({
	providedIn: 'root'
})

export class ConfigService implements ConfigConstants {
	public APP_INSIGHTS_EXCLUDED_DOMAINS: string;
	public APP_INSIGHTS_ID: string;
	public APP_INSIGHTS_TRACKER_ENABLED: string;
	public AUTH0_AUDIENCE: string;
	public AUTH0_CALLBACK_URL: string;
	public AUTH0_CLIENT_ID: string;
	public AUTH0_DOMAIN: string;
	public AUTH0_HOST_WHITELIST: string;
	public AUTH0_LOGOUT_URL: string;
	public CONSOLE_TRACKER_ENABLED: string;
	public DEBUG: string = 'false';
	public ENVIRONMENT: string;
	public GOOGLE_ANALYTICS_IDS: string;
	public GOOGLE_ANALYTICS_TRACKER_ENABLED: string;
	public MTG_API_DOMAIN: string;
	public DOCMAGIC_FEATURE_FLAG_ENABLED: string;
	public PAYMENT_FEATURE_FLAG_ENABLED: string;
	public HELOC_FEATURE_FLAG_ENABLED: string;
	public AUTHORIZE_NET_USERNAME: string;
	public AUTHORIZE_NET_PUBLIC_KEY: string;	
	public DOCUMENT_UPLOAD_LIMIT: string;

	public updateConfiguration(values: ConfigConstants): Promise<void> {
		return new Promise((resolve, reject) => {
			if (values) {
				this.APP_INSIGHTS_ID = values.APP_INSIGHTS_ID;
				this.APP_INSIGHTS_EXCLUDED_DOMAINS = values.APP_INSIGHTS_EXCLUDED_DOMAINS;
				this.APP_INSIGHTS_TRACKER_ENABLED = values.APP_INSIGHTS_TRACKER_ENABLED;
				this.AUTH0_AUDIENCE = values.AUTH0_AUDIENCE;
				this.AUTH0_CALLBACK_URL = values.AUTH0_CALLBACK_URL;
				this.AUTH0_CLIENT_ID = values.AUTH0_CLIENT_ID;
				this.AUTH0_DOMAIN = values.AUTH0_DOMAIN;
				this.AUTH0_HOST_WHITELIST = values.AUTH0_HOST_WHITELIST;
				this.AUTH0_LOGOUT_URL = values.AUTH0_LOGOUT_URL;
				this.CONSOLE_TRACKER_ENABLED = values.CONSOLE_TRACKER_ENABLED;
				this.DEBUG = values.DEBUG;
				this.ENVIRONMENT = values.ENVIRONMENT;
				this.GOOGLE_ANALYTICS_IDS = values.GOOGLE_ANALYTICS_IDS;
				this.GOOGLE_ANALYTICS_TRACKER_ENABLED = values.GOOGLE_ANALYTICS_TRACKER_ENABLED;
				this.MTG_API_DOMAIN = values.MTG_API_DOMAIN;
				this.DOCMAGIC_FEATURE_FLAG_ENABLED = values.DOCMAGIC_FEATURE_FLAG_ENABLED;
				this.PAYMENT_FEATURE_FLAG_ENABLED = values.PAYMENT_FEATURE_FLAG_ENABLED;
				this.HELOC_FEATURE_FLAG_ENABLED = values.HELOC_FEATURE_FLAG_ENABLED;
				this.AUTHORIZE_NET_USERNAME = values.AUTHORIZE_NET_USERNAME;
				this.AUTHORIZE_NET_PUBLIC_KEY = values.AUTHORIZE_NET_PUBLIC_KEY;
				this.DOCUMENT_UPLOAD_LIMIT = values.DOCUMENT_UPLOAD_LIMIT;
			}
			resolve();
		});

	}
}

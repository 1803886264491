export class DeclarationsApi {
	public OutstandingJudgments: boolean | null;
	public Bankruptcy: boolean | null;
	public BankruptciesDeclared: string[] | null = [];
	public PriorForeclosure: boolean | null;
	public PartyToLawsuit: boolean | null;
	public LoanForeclosureOrJudgment: boolean | null;
	public PresentlyDelinquent: boolean | null;
	public ChildSupportObligation: boolean | null;
	public AlimonyObligation: boolean | null;
	public BorrowedDownPayment: boolean | null;
	public CoMakerEndorserOfNote: boolean | null;
	public CitizenshipResidencyType: string | null;
	public PriorPropertyOwnerPastThreeYears: boolean | null;
	public PriorPropertyTitleType: string | null;
	public PriorPropertyUsageType: string | null;
	public MortgageForbearance: boolean | null;
	public CountryOfCitizenship: string | null;
	public SpecialBorrowerSellerRelationship: boolean | null;
	public UndisclosedBorrowedFunds: boolean | null;
	public UndisclosedBorrowedFundsAmount: number | null;
	public UndisclosedMortgageApplication: boolean | null;
	public UndisclosedCreditApplication: boolean | null;
	public PropertyProposedCleanEnergyLien: boolean | null;
	public PriorPropertyDeedInLieuConveyed: boolean | null;
	public PriorPropertyShortSaleCompleted: boolean | null;
}

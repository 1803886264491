import $ from 'jquery';
import _ from 'lodash';
import { InternalCardElement } from '../models/internal-card-element.model';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class CardService {
	public cardSlideTime: number = 0.35 * 1000;
	public cardFadeTime: number = 0.25 * 1000;

	public cardAnimationTime: number = this.cardSlideTime + this.cardFadeTime + 50; // 50ms delay for computer to catch up

	constructor() { }

	public collapseCard(
		$event: Event | null,
		cardSectionController: any,
		cardScopeList: any[],
		index: number,
		isDeleting: boolean
	): Promise<any> {
		return new Promise((resolve, reject) => {
			// browser bug requires double requestAnimationFrame to prevent flickers in certain browsers...
			// https://medium.com/@owencm/one-weird-trick-to-performant-touch-response-animations-with-react-9fe4a0838116
			// https://bugs.chromium.org/p/chromium/issues/detail?id=675795
			requestAnimationFrame(() => {
				requestAnimationFrame(() => {
					this.internalCollapseCard($event, cardSectionController, cardScopeList, index, isDeleting).then(() => {
						resolve(null);
					});
				});
			});
		});
	}

	public expandCard(
		$event: Event | null,
		cardSectionController: any,
		cardScopeList: any[],
		index: number,
		preventJump: boolean,
		skipSettingNewCardsToOld?: boolean
	): Promise<any> {
		return new Promise((resolve, reject) => {
			// browser bug requires double requestAnimationFrame to prevent flickers in certain browsers...
			// https://medium.com/@owencm/one-weird-trick-to-performant-touch-response-animations-with-react-9fe4a0838116
			// https://bugs.chromium.org/p/chromium/issues/detail?id=675795
			requestAnimationFrame(() => {
				requestAnimationFrame(() => {
					this.internalExpandCard(
						$event,
						cardSectionController,
						cardScopeList,
						index,
						preventJump,
						skipSettingNewCardsToOld
					).then((value) => {
						resolve(value);
					});
				});
			});
		});
	}

	public toggleCard($event: Event, cardSectionController: any, cardScopeList: any[], index: number): Promise<any> {
		const primaryCard = this.getPrimaryExpandableCard($event, index);

		if (primaryCard && primaryCard.rootElement && primaryCard.cardBody) {
			if (primaryCard.rootElement.classList.contains('expanded')) {
				return this.collapseCard($event, cardSectionController, cardScopeList, index, false);
			} else {
				return this.expandCard($event, cardSectionController, cardScopeList, index, false);
			}
		}

		return Promise.resolve();
	}

	private internalCollapseCard(
		$event: Event | null,
		cardSectionController: any,
		cardScopeList: any[],
		index: number,
		isDeleting: boolean
	): Promise<any> {
		this.handleEvent($event);

		const allCards = this.getExpandableCards();

		const primaryCard = this.getPrimaryExpandableCard($event, index);

		if (primaryCard && primaryCard.rootElement && primaryCard.cardHeader && primaryCard.cardBody) {
			return this.animate(cardSectionController, primaryCard, allCards, null, isDeleting, (card: InternalCardElement) => {
				const isPrimary = (card.rootElement === primaryCard.rootElement);

				if (isPrimary && isDeleting) {
					card.rootElement.classList.add('deleting');
					return 0;
				}

				let cardSubtitleHeight = 0;

				if (card.cardBody && document.defaultView && card.rootElement.classList.contains('expanded')) {
					cardSubtitleHeight = this.getElementHeight(card.cardSubtitle, true, true);
				}

				return this.getElementHeight(card.cardHeader, true) + cardSubtitleHeight;
			}).then(() => {
				if (cardScopeList && cardScopeList.length > 0) {
					for (const cardScope of
						cardScopeList) {
						if (cardScope.isNew !== undefined) {
							cardScope.isNew = false;
						}
					}
				}
			});
		}

		return Promise.resolve();
	}

	private internalExpandCard(
		$event: Event | null,
		cardSectionController: any,
		cardScopeList: any[],
		index: number,
		preventJump: boolean,
		skipSettingNewCardsToOld?: boolean
	): Promise<any> {
		this.handleEvent($event);

		const allCards = this.getExpandableCards();

		const primaryCard = this.getPrimaryExpandableCard($event, index);

		if (primaryCard && primaryCard.rootElement && primaryCard.cardBody && primaryCard.cardHeader) {
			if ($event && primaryCard.rootElement.classList.contains('expanded')) {
				return Promise.resolve();
			}

			return this.animate(cardSectionController, primaryCard, allCards, index, false, (card: InternalCardElement) => {
				const isPrimary = card.rootElement === primaryCard.rootElement;
				const isExpanded = card.rootElement.classList.contains('expanded');
				const cardBodyHeight = isPrimary ? this.getElementHeight(card.cardBody, true) : 0;
				const cardHeaderHeight = this.getElementHeight(card.cardHeader, true);

				let cardSubtitleHeight = 0;

				if (isPrimary && !isExpanded) {
					cardSubtitleHeight = -this.getElementHeight(card.cardSubtitle, true);
				} else if (!isPrimary && isExpanded) {
					cardSubtitleHeight = this.getElementHeight(card.cardSubtitle, true, true);
				}

				return cardBodyHeight + cardHeaderHeight + cardSubtitleHeight;
			}).then((value) => {
				if (!preventJump) {
					this.jumpToCard(primaryCard.rootElement);
				}

				if (!skipSettingNewCardsToOld) {
					if (cardScopeList && cardScopeList.length > 0) {
						for (let i = 0;
							i < cardScopeList.length;
							i++) {
							if (i !== index) {
								const cardScope = cardScopeList[i];
								if (cardScope.isNew !== undefined) {
									cardScope.isNew = false;
								}
							}
						}
					}
				}
				return value;
			});
		}

		return Promise.resolve();
	}

	private getCard(cardElement: HTMLElement): InternalCardElement {
		const internalCardElement = new InternalCardElement();
		internalCardElement.rootElement = cardElement;
		if (cardElement) {
			const $card = $(cardElement);

			if ($card) {
				internalCardElement.cardBody = $card.children('.nbkc-card-body')[0];
			}

			if ($card.children('.nbkc-card-header')[0]) {
				internalCardElement.cardHeader = $card.children('.nbkc-card-header')[0];

				if ($card.children('.nbkc-card-header').find('.nbkc-card-subtitle')[0]) {
					internalCardElement.cardSubtitle = $card.children('.nbkc-card-header').find('.nbkc-card-subtitle')[0];
				}
			}
		}

		return internalCardElement;
	}

	private getExpandableCards(): InternalCardElement[] {
		const $cards = $('.nbkc-card').not('.nbkc-card--no-animate');
		const internalCards = _.map($cards, ($card) => this.getCard($card));
		return _.filter(internalCards, (x: InternalCardElement) => {
			return (x.cardBody != null && x.cardHeader != null);
		});
	}

	private getPrimaryExpandableCard($event: Event | null, index: number): InternalCardElement | null {
		let expandableCards: any = [];

		if ($event) {
			let eventCard;
			if ($event.target !== undefined) {
				eventCard = $(($event.target) as any).closest('.nbkc-card').not('.nbkc-card--no-animate')[0];
			} else if ($event.srcElement !== undefined) {
				eventCard = $(($event.srcElement) as any).closest('.nbkc-card').not('.nbkc-card--no-animate')[0];
			}
			expandableCards.push(this.getCard(eventCard as HTMLElement));
		} else {
			expandableCards = this.getExpandableCards();
		}

		if (expandableCards && expandableCards.length > 0) {
			let card: InternalCardElement;
			if ($event) {
				card = expandableCards[0];
			} else {
				card = expandableCards[index];
			}
			return card;
		}

		return null;
	}

	private jumpToCard(card?: HTMLElement, forceJump?: boolean): void {
		const jump = (): void => {
			if (document.documentElement.clientWidth <= 767 || forceJump) {
				card.scrollIntoView();
				window.scrollBy(0, -65);
			}
		};

		if (!card) {
			setTimeout(() => {
				card = $('.nbkc-card').not('.nbkc-card--no-animate').last()[0];
				jump();
			}, 500);
		} else {
			jump();
		}
	}

	private handleEvent($event: Event | null): void {
		if ($event) {
			// Prevent event bubbling
			if ($event.stopPropagation) {
				$event.stopPropagation();
			}
			if ($event.preventDefault) {
				$event.preventDefault();
			}
			$event.cancelBubble = true;
			$event.returnValue = false;
		}
	}

	private animate(
		cardSectionController: any,
		primaryCard: InternalCardElement,
		allCards: InternalCardElement[],
		index: number | null,
		isDeleting: boolean,
		calculateCardHeight: (card: InternalCardElement) => number,
	): Promise<any> {
		_.forEach(allCards, (card) => {
			card.rootElement.style.height = this.getElementHeight(card.rootElement, false) + 'px';
			if (card.rootElement === primaryCard.rootElement || card.rootElement.classList.contains('expanded')) {
				card.cardBody.classList.add('fading-heading');
				if (card.rootElement.classList.contains('fade-heading')) {
					card.cardHeader.classList.add('fading-heading');
				}
			}
		});
		return new Promise((resolve, reject) => {
			// browser bug requires double requestAnimationFrame to prevent flickers in certain browsers...
			// https://medium.com/@owencm/one-weird-trick-to-performant-touch-response-animations-with-react-9fe4a0838116
			// https://bugs.chromium.org/p/chromium/issues/detail?id=675795
			requestAnimationFrame(() => {
				requestAnimationFrame(() => {
					_.forEach(allCards, (card) => {
						card.rootElement.style.height = calculateCardHeight(card) + 'px';
					});
					setTimeout(() => {
						if (cardSectionController && typeof (cardSectionController) === 'object') {
							cardSectionController.expandedCardIndex = index;
						}
						setTimeout(() => {
							_.forEach(allCards, (card) => {
								let resetHeight = true;

								if (card.rootElement === primaryCard.rootElement || !card.rootElement.classList.contains('expanded')) {
									card.cardBody.classList.remove('fading-heading');
									card.cardHeader.classList.remove('fading-heading');
								}

								if (isDeleting && card.rootElement === primaryCard.rootElement) {
									card.rootElement.classList.add('deleted');
									card.rootElement.classList.remove('deleting');
									resetHeight = false;
								}

								if (resetHeight) {
									// browser bug requires double requestAnimationFrame to prevent flickers in certain browsers...
									// https://medium.com/@owencm/one-weird-trick-to-performant-touch-response-animations-with-react-9fe4a0838116
									// https://bugs.chromium.org/p/chromium/issues/detail?id=675795
									requestAnimationFrame(() => {
										requestAnimationFrame(() => {
											card.rootElement.style.height = null;
										});
									});
								}
							});
							resolve(primaryCard);
						}, 50);
					}, this.cardAnimationTime);
				});
			});
		});
	}

	private getElementHeight(element: HTMLElement, includeMargins: boolean, forceDisplay: boolean = false): number {
		if (!element) {
			return 0;
		}

		const originalPosition = element.style.position;
		const originalVisibility = element.style.visibility;
		const originalDisplay = element.style.display;

		if (forceDisplay) {
			element.style.position = 'absolute';
			element.style.visibility = 'hidden';
			element.style.display = 'block';
		}

		let elementHeight = element.offsetHeight;

		if (includeMargins && document.defaultView) {
			elementHeight += parseInt(document.defaultView.getComputedStyle(element, '').getPropertyValue('margin-top'), 10);
			elementHeight += parseInt(document.defaultView.getComputedStyle(element, '').getPropertyValue('margin-bottom'), 10);
		}

		if (forceDisplay) {
			element.style.position = originalPosition;
			element.style.visibility = originalVisibility;
			element.style.display = originalDisplay;
		}

		return elementHeight;
	}
}

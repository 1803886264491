import { PortfolioRequestForm } from "./portfolio-request-form.model";
import { PortfolioRelationshipForm } from "./portfolio-relationship-form.model";


export class PortfolioForm {
    public id : string; 
    public status : string;
    public requests : PortfolioRequestForm[];
    public relationships : PortfolioRelationshipForm[]; 
    public internalContact : PortfolioRelationshipForm; 
}
import { Validatable, Validate, Validators } from '@nbkc/validation';

export class UserProfile extends Validatable {
	@Validate(Validators.required('Please enter a first name.'))
	public FirstName: string;
	@Validate(Validators.required('Please enter a last name.'))
	public LastName: string;
	@Validate(Validators.required('Please enter an email address.'))
	public EmailAddress: string;
	@Validate(Validators.phoneNumber('Please enter a valid phone number.'))
	public PrimaryPhone: string;
	public LoanApplicationUrl: string;
	public Roles: string[];
	public validationEnabled: boolean = true; // Temp fix
}

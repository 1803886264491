import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationPurposes, CacheKeys, SectionNames } from '@constants';
import { Lookups } from '@lookups/lookups';
import { ApplicationView } from '@models/application-view.model';
import { Tracker } from '@nbkc/tracker-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppState } from '@services/app-state.service';
import { ApplicationService } from '@services/application.service';
import { ErrorService } from '@services/error.service';
import { GrammarService } from '@services/grammar.service';
import { UserService } from '@services/user.service';
import { convertCollectionToMap } from '@utilities/collection-to-map';
import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import { finalize } from 'rxjs/operators';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { LoanApplicationStartedEvent } from 'src/app/shared/tracking/events';
import { ConfigService } from '@services/config.service';
import { CacheService } from '@services/cache.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit, OnDestroy {
  	public apps: ApplicationView[];
	public mostRecentApplication: ApplicationView;
	public submittedApps: ApplicationView[] = [];
	public inProgressApps: ApplicationView[] = [];
	public appPurposeMap: Map<string, string> = convertCollectionToMap<string, string>(Lookups.ApplicationPurposes, 'id', 'description');
	public isHelocEnabled: boolean = false;

	public purchaseIcon: string = "fa fa-home";
	public refinanceIcon: string = "fa fa-balance-scale";
	public cashOutRefinanceIcon: string = "fa fa-money-bill-wave"
	public helocIcon: string = "fa fa-piggy-bank";
	
	constructor(
		public appState: AppState,
		public applicationService: ApplicationService,
		public errorService: ErrorService,
		public grammarService: GrammarService,
		public userService: UserService,
		public router: Router,
		public modalService: NgbModal,
		public tracker: Tracker,
		public config: ConfigService,
		private cacheService: CacheService,
	) {
	}

	ngOnInit(): void {
		if(this.config.HELOC_FEATURE_FLAG_ENABLED?.toLowerCase() === 'true'){
			this.isHelocEnabled = true;
		}
		this.loadAll();
	}

	public loadAll(): void {
		const appPurpose : string = ApplicationPurposes.matchPurpose(this.cacheService.get(CacheKeys.requestedApplicationType));
		this.cacheService.clear(CacheKeys.requestedApplicationType);
		this.appState.notReady();
		this.appState.loading('Gathering all of your applications');
		this.applicationService.loadAllForUser().pipe(finalize(() => {
			this.appState.ready();
			this.appState.notLoading();
		})).subscribe((response: ApplicationView[]) => {
			if (response) {
				const apps = response;
				if (!apps || apps.length === 0 && appPurpose) {
					this.startCreateNewApplicationFlow(appPurpose)
				}else{
					this.apps = apps;
					this.mostRecentApplication = _.orderBy(this.apps, (app: ApplicationView) => {
						return app.ModifiedDt;
					}, 'desc')[0];
					_.forEach(apps, (app: ApplicationView) => {
						if (app.ApplicationStatus === 'Submitted') {
							this.submittedApps.push(app);
						} else {
							this.inProgressApps.push(app);
						}
					});
				}
			}
		}, (response) => {
			this.errorService.handle({
				data: response,
				display: 'error-page',
				message: 'Cannot load your applications. Use the button below to try again.',
				retryAction: () => {
					this.router.navigate(['status']);
				},
				retryText: 'Load Your Applications'
			});
		});
	}

	public startCreateNewApplicationFlow(purpose: string): void {
		const mostRecentApplicationAge = moment().diff(moment(this.mostRecentApplication?.ModifiedDt as any), 'days');
		if (this.mostRecentApplication && mostRecentApplicationAge < 30) {
			const modalRef = this.modalService.open(ConfirmModalComponent, {
				windowClass: 'loan-app-modal'
			});
			modalRef.componentInstance.mostRecentApplication = this.mostRecentApplication;
			modalRef.result.then(() => {
				this.createNewApplication(purpose);
			}, (reason) => {
				if (reason.goToApplication) {
					this.viewApp(this.mostRecentApplication);
				}
				if (reason.goToDocumentUploads) {
					this.router.navigate(['borrower-portal', this.mostRecentApplication.PortfolioId]);
				}
			});
		} else {
			this.createNewApplication(purpose);
		}
	}

	public createNewApplication(purpose: string): void {
    
      this.applicationService.createNewAppAndRouteToIt(purpose).subscribe(() => {
        this.tracker.event(new LoanApplicationStartedEvent());
      });
	}

	public viewApp(app: ApplicationView): void {
		this.appState.loading('Loading your application');
		if (app.ApplicationPurpose) {
			this.router.navigate(['apply', app.ApplicationId, SectionNames.loan]).then(() => this.appState.notLoading());
		}
	}

	public viewSubmittedApp(app: ApplicationView): void {
		this.appState.loading('Loading your application');
		setTimeout(() => {
			this.router.navigate(['/borrower-portal', app.PortfolioId]).then(() => this.appState.notLoading());
		}, 1250);
	}

	public getLoanDisplayText(app: ApplicationView): string {
		if (app.ApplicationPurpose && app.RequestedAmount) {
			return this.appPurposeMap.get(app.ApplicationPurpose) + ' Application for ' + numeral(app.RequestedAmount).format('($0,0)');
		} else if (app.ApplicationPurpose) {
			return this.appPurposeMap.get(app.ApplicationPurpose) + ' Application' || '';
		} else {
			return 'New Application';
		}
	}

	public getLoanIcon(applicationPurpose: string): string{
		let returnIcon : string = "";

		switch(applicationPurpose){
			case ApplicationPurposes.purchase: 
				returnIcon = this.purchaseIcon; 
				break;
			case ApplicationPurposes.cashOutRefinance: 
				returnIcon = this.cashOutRefinanceIcon; 
				break; 
			case ApplicationPurposes.refinance: 
				returnIcon = this.refinanceIcon;
				break;
			case ApplicationPurposes.heloc: 
				returnIcon = this.helocIcon;
				break;
			default: 
				break;
		}

		return returnIcon; 
	}

	ngOnDestroy(): void {
		this.errorService.dismiss();
	}
}

export class EmailApi {
	public IsPrimary: boolean;
	public Address: string;
	public EmailAddressType: string;

	constructor(address: string, primary: boolean) {
		this.Address = address;
		this.IsPrimary = primary;
	}
}

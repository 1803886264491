import { Validatable, Validate, ValidateIf, Validators } from '@nbkc/validation';
import { BorrowerDeclaredBankruptciesForm } from './borrower-declared-bankruptcies.model';
import { IBorrowerDeclarationsForm } from './borrower-declarations';

export class BorrowerDeclarations2020Form extends Validatable implements IBorrowerDeclarationsForm {
	public borrowerIndex: number;
	@Validate(Validators.required()) public bankruptcy: boolean | null;
	@Validate(Validators.required()) public citizenship: string | null;
	@Validate(Validators.required()) public coSignerOnLoan: boolean | null;
	@Validate(Validators.required()) public delinquentOnFederalDebtOrOtherLoan: boolean | null;
	@Validate(Validators.required()) public foreclosures: boolean | null;
	@Validate(Validators.required()) public haveAlimonyPayments: boolean | null;
	@Validate(Validators.required()) public haveChildSupportPayments: boolean | null;
	@Validate(Validators.required()) public outstandingJudgments: boolean | null;

	@ValidateIf(`this.parentRef.parentRef.loan.occupancyType === 'PrimaryResidence'`, Validators.required())
	public ownershipInterestPastThreeYears: boolean | null;

	@Validate(Validators.required()) public partyToLawsuit: boolean | null;
	@Validate(Validators.required()) public priorPropertyDeedInLieuConveyed: boolean | null;
	@Validate(Validators.required()) public priorPropertyShortSaleCompleted: boolean | null;
	@Validate(Validators.required()) public propertyProposedCleanEnergyLien: boolean | null;
	@Validate(Validators.required()) public undisclosedBorrowedFunds: boolean | null;
	@Validate(Validators.required()) public undisclosedCreditApplication: boolean | null;
	@Validate(Validators.required()) public undisclosedMortgageApplication: boolean | null;

	@ValidateIf('this.haveAlimonyPayments === true', Validators.required())
	public alimonyPayments: number | null;

	@ValidateIf('this.haveChildSupportPayments === true', Validators.required())
	public childSupportPayments: number | null;

	@ValidateIf(`this.citizenship === 'NonPermanentResidentAlien'`, Validators.required())
	public countryOfCitizenship: string | null;

	@ValidateIf('this.bankruptcy && this.declaredBankruptcies && !this.declaredBankruptcies.anyChecked()', Validators.required())
	public declaredBankruptcies: BorrowerDeclaredBankruptciesForm | null;

	@ValidateIf(`this.parentRef.parentRef.loan.occupancyType === 'PrimaryResidence' && this.ownershipInterestPastThreeYears === true`, Validators.required())
	public priorPropertyTitleType: string | null;

	@ValidateIf(`this.parentRef.parentRef.loan.occupancyType === 'PrimaryResidence' && this.ownershipInterestPastThreeYears === true`, Validators.required())
	public priorPropertyUsageType: string | null;

	// eslint-disable-next-line max-len
	@ValidateIf(`this.parentRef && this.parentRef.parentRef && this.parentRef.parentRef.loan && this.parentRef.parentRef.loan.purpose === 'Purchase'`, Validators.required())
	public specialBorrowerSellerRelationship: boolean | null;

	@ValidateIf('this.undisclosedBorrowedFunds', Validators.required())
	public undisclosedBorrowedFundsAmount: number | null;

	constructor(borrowerIndex: number) {
		super();
		this.borrowerIndex = borrowerIndex;
		this.declaredBankruptcies = new BorrowerDeclaredBankruptciesForm();

		this.alimonyPayments = null;
		this.bankruptcy = null;
		this.childSupportPayments = null;
		this.citizenship = null;
		this.delinquentOnFederalDebtOrOtherLoan = null;
		this.foreclosures = null;
		this.haveAlimonyPayments = null;
		this.haveChildSupportPayments = null;
		this.outstandingJudgments = null;
		this.partyToLawsuit = null;
		this.priorPropertyDeedInLieuConveyed = null;
		this.priorPropertyShortSaleCompleted = null;
		this.propertyProposedCleanEnergyLien = null;
		this.specialBorrowerSellerRelationship = null;
		this.undisclosedBorrowedFunds = null;
		this.undisclosedBorrowedFundsAmount = null;
		this.undisclosedCreditApplication = null;
		this.undisclosedMortgageApplication = null;
	}
}
import { DeclarationsApi } from '@models/api/declarations';
import { AdapterBase, AdapterOptions } from '@utilities/adapter-base';
import { BorrowerDeclarations2020Form } from '@models/form/declarations/borrower-declarations-2020.model';
import { BankruptcyChapters } from '../../constants/bankruptcy-chapters';
import _ from 'lodash';

export class DeclarationsApiToBorrowerDeclarations2020FormAdapter extends AdapterBase<DeclarationsApi, BorrowerDeclarations2020Form> {
	public adapt(source: DeclarationsApi, options: CurrentAdapterOptions): BorrowerDeclarations2020Form {
		const dest = new BorrowerDeclarations2020Form(options.borrowerIndex);
		dest.bankruptcy = source.Bankruptcy;
		dest.citizenship = source.CitizenshipResidencyType;
		dest.coSignerOnLoan = source.CoMakerEndorserOfNote;
		dest.countryOfCitizenship = source.CountryOfCitizenship;
		dest.delinquentOnFederalDebtOrOtherLoan = source.PresentlyDelinquent;
		dest.foreclosures = source.PriorForeclosure;
		dest.haveAlimonyPayments = source.AlimonyObligation;
		dest.haveChildSupportPayments = source.ChildSupportObligation;
		dest.outstandingJudgments = source.OutstandingJudgments;
		dest.ownershipInterestPastThreeYears = source.PriorPropertyOwnerPastThreeYears;
		dest.partyToLawsuit = source.PartyToLawsuit;
		dest.priorPropertyDeedInLieuConveyed = source.PriorPropertyDeedInLieuConveyed;
		dest.priorPropertyShortSaleCompleted = source.PriorPropertyShortSaleCompleted;
		dest.priorPropertyTitleType = source.PriorPropertyTitleType;
		dest.priorPropertyUsageType = source.PriorPropertyUsageType;
		dest.propertyProposedCleanEnergyLien = source.PropertyProposedCleanEnergyLien;
		dest.specialBorrowerSellerRelationship = source.SpecialBorrowerSellerRelationship;
		dest.undisclosedBorrowedFunds = source.UndisclosedBorrowedFunds;
		dest.undisclosedBorrowedFundsAmount = source.UndisclosedBorrowedFundsAmount;
		dest.undisclosedCreditApplication = source.UndisclosedCreditApplication;
		dest.undisclosedMortgageApplication = source.UndisclosedMortgageApplication;

		if (source.BankruptciesDeclared?.length > 0) {
			_.each(source.BankruptciesDeclared, (bankruptcyChapter: string) => {
				if (bankruptcyChapter === BankruptcyChapters.seven) {
					dest.declaredBankruptcies.chapter7 = true;
				}
				if (bankruptcyChapter === BankruptcyChapters.eleven) {
					dest.declaredBankruptcies.chapter11 = true;
				}
				if (bankruptcyChapter === BankruptcyChapters.twelve) {
					dest.declaredBankruptcies.chapter12 = true;
				}
				if (bankruptcyChapter === BankruptcyChapters.thirteen) {
					dest.declaredBankruptcies.chapter13 = true;
				}
			})
		}

		return dest;
	}
}

interface CurrentAdapterOptions extends AdapterOptions {
	borrowerIndex: number;
}

export class ApplicationPurposes {
	public static purchase: string = 'Purchase';
	public static refinance: string = 'Refinance';
	public static unknown: string = 'Unknown';
	public static preApproval: string = 'PreApproval';
	public static cashOutRefinance: string = 'CashOutRefinance';
	public static heloc: string = 'HELOC';

	public static matchPurpose(purpose : string) : string {
		let matchResult: string = '';

		if(!purpose){
			return matchResult;
		}

		switch(purpose.toLowerCase()){
			case ApplicationPurposes.purchase.toLowerCase() :
				matchResult = ApplicationPurposes.purchase;
				break;
			case ApplicationPurposes.refinance.toLowerCase() :
				matchResult = ApplicationPurposes.refinance;
				break;
			case ApplicationPurposes.cashOutRefinance.toLowerCase() :
				matchResult = ApplicationPurposes.cashOutRefinance;
				break;
			case ApplicationPurposes.heloc.toLowerCase() : 
				matchResult = ApplicationPurposes.heloc;
				break;
			default :
				break; 
		}

		return matchResult;
	}
}

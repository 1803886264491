import { AdapterBase } from '@utilities/adapter-base';
import { AssetApi } from '@models/api/asset';
import { AccountForm } from '@models/form/account.model';
import _ from 'lodash';
import { NumberHelper } from '@utilities/helpers';

export class AssetApiToAccountFormAdapter extends AdapterBase<AssetApi, AccountForm> {
	public adapt(source: AssetApi): AccountForm {
		const account = new AccountForm();
		account.amount = NumberHelper.numberToString(source.Value);
		account.holders = _.map(source.BorrowersWithInterestInAsset, (borrowerIndex: number) => {
			return borrowerIndex;
		});
		account.name = source.NameOfHolder;
		account.gift = source.ContainsGift;
		account.assetType = source.AssetType || null;
		return account;
	}
}

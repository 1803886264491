import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MaintenanceStatus } from '@models/maintenance-status.model';

@Injectable({
	providedIn: 'root'
})
export class MaintenanceService {
	private isInMaintenanceMode: boolean = false;
	private maintenanceWorker: ReturnType<typeof setTimeout> = null;
	private offlineMessage: string;
	private reloadTimer: ReturnType<typeof setTimeout> = null;

	public get isAppOffline(): boolean {
		return this.isInMaintenanceMode;
	}

	constructor(public router: Router) {
		(window as any).maintenance = {
			takeApplicationOffline: () => this.takeApplicationOffline(),
			setApplicationOnline: () => this.setApplicationOnline(),
			forceClientReload: () => this.forceClientReload(),
			cancelClientReload: () => this.cancelClientReload()
		};
	}

	public takeApplicationOffline(): void {
		const status = new MaintenanceStatus();
		status.isSiteOffline = true;
		this.setMaintenanceStatus(status);
	}

	public setApplicationOnline(): void {
		const status = new MaintenanceStatus();
		status.isSiteOffline = false;
		this.setMaintenanceStatus(status);
	}

	public setMaintenanceStatus(maintenanceStatus: MaintenanceStatus): void {
		this.isInMaintenanceMode = maintenanceStatus.isSiteOffline || false;
		this.offlineMessage = maintenanceStatus.customMessage;

		if (this.isInMaintenanceMode) {
			this.startMaintenanceMode();
		}
	}

	public forceClientReload(): void {
		if (!this.reloadTimer) {
			this.reloadTimer = setTimeout(() => window.location.reload(), 30000);
		}
	}

	public cancelClientReload(): void {
		clearTimeout(this.reloadTimer);
		this.reloadTimer = null;
	}

	private startMaintenanceMode(): void {
		this.router.navigate(['/app-offline']); // TODO: Ensure user stays at page
		this.maintenanceWorker = setInterval(() => {
			this.sendHeartbeat();
		}, 5000);
	}

	private sendHeartbeat(): void {
		this.isInMaintenanceMode = true;
		// eslint-disable-next-line no-console
		console.info(`Application is offline`, this.offlineMessage);
	}

	// TODO: Allow full app reload
}

import { Directive, ElementRef, HostListener, OnDestroy, OnInit, Renderer2 } from '@angular/core';

@Directive({
	selector: '[chevron]'
})
export class ChevronDirective implements OnInit, OnDestroy {
	public wrapped: boolean = false;
	public wrapperDiv: any;
	public parentDiv: any;

	constructor(public el: ElementRef, public renderer: Renderer2) { }

	@HostListener('blur')
	public blur(): void {
		this.wrapperDiv.classList.remove('focused');
	}

	@HostListener('focus')
	public focus(): void {
		this.wrapperDiv.classList.add('focused');
	}

	public ngOnDestroy(): void {
		setTimeout(() => {
			if (this.wrapperDiv) {
				this.wrapperDiv.replaceWith(...Array.from(this.wrapperDiv.childNodes));
			}
		}, 500);
	}

	ngOnInit(): void {
		this.wrapped = true;
		this.wrapperDiv = this.renderer.createElement('div');
		this.renderer.addClass(this.wrapperDiv, 'select-wrapper');
		this.parentDiv = this.el.nativeElement.parentNode;
		this.renderer.insertBefore(this.parentDiv, this.wrapperDiv, this.el.nativeElement);
		this.renderer.appendChild(this.wrapperDiv, this.el.nativeElement);
	}
}

import { IdDescriptionPair } from './lookups';

export const UnitTypes: IdDescriptionPair[] = [
	{
		id: null,
		description: ''
	},
	{
		id: 'Apartment',
		description: 'Apartment'
	},
	{
		id: 'Building',
		description: 'Building'
	},
	{
		id: 'Condo',
		description: 'Condo'
	},
	{
		id: 'Floor',
		description: 'Floor'
	},
	{
		id: 'Lot',
		description: 'Lot'
	},
	{
		id: 'Office',
		description: 'Office'
	},
	{
		id: 'Penthouse',
		description: 'Penthouse'
	},
	{
		id: 'Suite',
		description: 'Suite'
	},
	{
		id: 'Unit',
		description: 'Unit'
	}
]
import { FulfillmentLinkedAcccountApi } from './fulfillment-linked-account';
import { FulfillmentDocumentApi } from './fulfillment-documents';

export class BorrowerRequestFulfillmentApi {
	public RequestSourceId: string;
	public RequestSourceType: string;
	public RequestType: string;
	public RequestId: string;
	public FulfillmentComplete: boolean | null;
	public FulfillmentNotes: string[];
	public Documentation: FulfillmentDocumentApi;
	public LinkedAccount: FulfillmentLinkedAcccountApi;
}
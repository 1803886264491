import { Section } from '@models/section.model';
import { SectionNames } from '@constants';

export const Sections: Section[] = [
	new Section({
		name: SectionNames.loan,
		modelName: 'loan',
		displayName: 'Loan Details',
		summaryComponent: 'LoanSummaryComponent',
	}),
	new Section({
		name: SectionNames.borrowers,
		modelName: 'borrowers',
		displayName: 'Who\'s Applying',
		summaryComponent: 'BorrowersSummaryComponent',
	}),
	new Section({
		name: SectionNames.residenceHistory,
		modelName: 'residences',
		displayName: 'Residence History',
		summaryComponent: 'ResidenceHistorySummaryComponent',
	}),
	new Section({
		name: SectionNames.employment,
		modelName: 'employment',
		displayName: 'Employment History',
		summaryComponent: 'EmploymentSummaryComponent',
	}),
	new Section({
		name: SectionNames.otherIncome,
		modelName: 'income',
		displayName: 'Other Income',
		summaryComponent: 'IncomeSummaryComponent',
	}),
	new Section({
		name: SectionNames.assets,
		modelName: 'accounts',
		displayName: 'Assets & Accounts',
		disabled: true,
		summaryComponent: 'AssetsSummaryComponent',
	}),
	new Section({
		name: SectionNames.realEstate,
		modelName: 'realEstate',
		displayName: 'Additional Real Estate',
		summaryComponent: 'RealEstateSummaryComponent',
	}),
	new Section({
		name: SectionNames.declarations,
		modelName: 'declarations',
		displayName: 'Declarations',
		summaryComponent: 'DeclarationsSummaryComponent',
	}),
	new Section({
		name: SectionNames.demographics,
		modelName: 'demographics',
		displayName: 'Demographics',
		summaryComponent: 'DemographicsSummaryComponent',
	}),
	new Section({
		name: SectionNames.review,
		displayName: 'Review & Submit',
		summaryComponent: null,
		hiddenFromReviewPage: true
	})
];

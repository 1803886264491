import { AdapterBase } from '@utilities/adapter-base';
import { BorrowerRequestFulfillment } from '@models/form/borrower-request-fulfillment.model';
import { BorrowerRequestFulfillmentApi } from '@models/api/borrower-request-fulfillment';

export class BorrowerRequestFulfillmentFormToBorrowerRequestFulfillmentApiAdapter
	extends AdapterBase<BorrowerRequestFulfillment, BorrowerRequestFulfillmentApi>
	{
		adapt(source: BorrowerRequestFulfillment): BorrowerRequestFulfillmentApi {
			const dest: BorrowerRequestFulfillmentApi = new BorrowerRequestFulfillmentApi();
			dest.FulfillmentComplete = source.FulfillmentComplete;
			dest.RequestSourceId = source.RequestSourceId;
			dest.RequestType = source.RequestType;
			return dest;
		}
	}
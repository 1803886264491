import { AdapterBase } from '@utilities/adapter-base';
import { EmploymentForm } from '@models/form/employment.model';
import { EmployerApi } from '@models/api/employer';
import { PhoneApi } from '@models/api/phone';
import numeral from 'numeral';
import { AddressFormToAddressApiAdapter } from './address-form-to-address-api.adapter';
import moment from 'moment';
import { ConfigurationValues } from '@constants';

export class EmploymentFormToEmployerApiAdapter extends AdapterBase<EmploymentForm, EmployerApi> {
	private _addressFormToAddressApiAdapter: AddressFormToAddressApiAdapter = new AddressFormToAddressApiAdapter();

	adapt(source: EmploymentForm): EmployerApi {
		const dest = new EmployerApi();
		dest.BusinessName = source.companyName;
		dest.Title = source.jobTitle;
		dest.Phone = new PhoneApi(source.phone, true);
		dest.TimeInLineOfWorkYears = source.yearsInCareer;
		dest.StartDate = this.verifyAndReturnDate(source.startDate);
		dest.EndDate = this.verifyAndReturnDate(source.endDate);
		dest.Address = this._addressFormToAddressApiAdapter.adapt(source.address);
		dest.CurrentEmployment = source.isCurrent;
		dest.SelfEmployed = source.selfEmployed;
		dest.EmployerIsFamily = source.isFamily;
		dest.EmployerIsPartyToTransaction = source.isPartyToTransaction;
		dest.OwnershipPercentage = source.ownershipPercentage ? numeral(source.ownershipPercentage.toString().replace('%', '')).value() : null
		dest.AnnualBaseSalary = this.getAnnualIncome(source.monthlyIncome);
		dest.AnnualBonus = numeral(source.annualBonusIncome).value();
		dest.AnnualCommission = numeral(source.annualCommissionIncome).value();
		dest.AnnualOvertime = numeral(source.annualOvertimeIncome).value();
		return dest;
	}

	private verifyAndReturnDate(date: string): string | null {
		if (date && moment(date, ConfigurationValues.dateFormat, true).isValid()) {
			return date;
		} else {
			return null;
		}
	}

	private getAnnualIncome(monthlyIncome: string ): number{
		const intMonthlyIncome = numeral(monthlyIncome).value()
		return intMonthlyIncome * 12;
	}
}

<div class="long-form-nav d-none d-sm-block">
 
    <div class="left-menu-header">To Do:</div>
    <div class="left-menu-item-container">
        <div class="left-nav-item-row" *ngFor="let todo of todoItems; index as $index">
            <div class="left-nav-item" [ngClass]="{'bold-item' : todo.isActive}">
               <i [ngClass]="{'fa fa-check-circle' : todo.isCompleted}"></i> {{todo.name}} <span [ngClass]="{'active-indicator': todo.isActive}"></span>
            </div>
        </div>
    </div>
</div>

import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
	selector: '[summaryHost]'
})
export class SummaryHostDirective {

	constructor(public view: ViewContainerRef) { }

}

import { AdapterBase } from '@utilities/adapter-base';
import { AddressApi } from '@models/api/address';
import { AddressForm } from '@models/form/address.model';

export class AddressApiToAddressFormAdapter extends AdapterBase<AddressApi, AddressForm> {
	public adapt(source: AddressApi): AddressForm {
		const dest = new AddressForm();
		if (source) {
			dest.address1 = source.AddressLine1;
			dest.state = source.State;
			dest.city = source.City;
			dest.zip = source.PostalCode;
			dest.unitType = source.UnitType;
			dest.unitNumber = source.UnitNumber;
			dest.county = source.County;
			dest.countryCode = source.CountryCode;
		}
		return dest;
	}
}

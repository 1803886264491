import { Component } from "@angular/core";

export abstract class CardRightCornerBannerToken {}

@Component({
  selector: 'lib-card-right-corner-banner-component',
  templateUrl: './card-right-corner-banner.component.html',
  styleUrls: ['./card-right-corner-banner.component.scss'],
  providers: [
    {provide: CardRightCornerBannerToken, useExisting: CardRightCornerBannerComponent}
  ]
})
 export class CardRightCornerBannerComponent extends CardRightCornerBannerToken{
    constructor() {
        super();
    }

 }
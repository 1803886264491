import { AdapterBase } from '@utilities/adapter-base';
import { DeclarationsApi } from '@models/api/declarations';
import { BorrowerDeclarations2009Form } from '@models/form/declarations/borrower-declarations-2009.model';

export class BorrowerDeclarations2009FormToDeclarationsApiAdapter extends AdapterBase<BorrowerDeclarations2009Form, DeclarationsApi> {
	adapt(source: BorrowerDeclarations2009Form): DeclarationsApi {
		const dest = new DeclarationsApi();
		dest.AlimonyObligation = source.haveAlimonyPayments;
		dest.Bankruptcy = source.bankruptcy;
		dest.BorrowedDownPayment = source.partOfDownPaymentBorrowed;
		dest.ChildSupportObligation = source.haveChildSupportPayments;
		dest.CitizenshipResidencyType = source.citizenship;
		dest.CoMakerEndorserOfNote = source.coSignerOnLoan;
		dest.LoanForeclosureOrJudgment = source.obligatedOnLoanForeclosureOrJudgment;
		dest.MortgageForbearance = source.mortgageForbearance;
		dest.OutstandingJudgments = source.outstandingJudgments;
		dest.PartyToLawsuit = source.partyToLawsuit;
		dest.PresentlyDelinquent = source.delinquentOnFederalDebtOrOtherLoan;
		dest.PriorForeclosure = source.foreclosures;
		dest.PriorPropertyOwnerPastThreeYears = source.ownershipInterestPastThreeYears;
		dest.PriorPropertyTitleType = source.priorPropertyTitleType;
		dest.PriorPropertyUsageType = source.priorPropertyUsageType;
		return dest;
	}
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FileBatch } from '../models/file-batch.model';

@Component({
	selector: 'app-document-upload-progress-bar',
	templateUrl: './document-upload-progress-bar.component.html',
	styleUrls: ['./document-upload-progress-bar.component.scss']
})
export class DocumentUploadProgressBarComponent implements OnInit, OnDestroy {

	@Input() fileBatch: FileBatch;
	@Output() progressComplete: EventEmitter<FileBatch> = new EventEmitter();

	private timeoutIncrement: number = 5000;
	private progressInterval: ReturnType<typeof setInterval>;


	ngOnInit() {

		this.progressInterval = setInterval(() => {
			if (this.fileBatch.IsComplete && !this.fileBatch.Files.find(x => !x.HasError)) {
				this.progressComplete.emit(this.fileBatch);
			}

			if (this.fileBatch.CompletionPercent < 30) {
				this.fileBatch.CompletionPercent += 15;
			}
			if (this.fileBatch.CompletionPercent >= 30 && this.fileBatch.CompletionPercent < 90) {
				this.fileBatch.CompletionPercent += 10;
			}
			if (this.fileBatch.CompletionPercent === 90 && this.fileBatch.IsComplete) {
				this.fileBatch.CompletionPercent = 100;
				setInterval(() => {
					this.progressComplete.emit(this.fileBatch);
				}, 1000)
			}
		}, this.timeoutIncrement / 10);

	}

	ngOnDestroy() {
		clearInterval(this.progressInterval);
	}

}

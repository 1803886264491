import { DemographicCheckOption } from '@lookups/demographic-check-option.model';
import { Lookups } from '@lookups/lookups';
import { DemographicAnswer } from '@models/form/demographics/demographic-helpers/demographic-answer.model';
import { Validatable, ValidateIf, Validators } from '@nbkc/validation';
import _ from 'lodash';
import { DemographicsSectionKeyMap } from './demographic-helpers/demographics-section-key-map';
import { DemographicsEthnicity } from './demographic-sections/demographics-ethnicity.model';
import { DemographicsRace } from './demographic-sections/demographics-race.model';
import { DemographicsSex } from './demographic-sections/demographics-sex.model';

export class BorrowerDemographicForm extends Validatable {

	public borrowerIndex: number;
	@ValidateIf(`this.sex.oneChecked() !== true`, Validators.required())
	public sex: DemographicsSex;
	@ValidateIf(`this.ethnicity.oneChecked() !== true`, Validators.required())
	public ethnicity: DemographicsEthnicity;
	@ValidateIf(`this.race.oneChecked() !== true`, Validators.required())
	public race: DemographicsRace;
	private demoMap: any = this.createMapForDemographicsOptions(Lookups.DemographicsCheckOptions);

	constructor(borrowerIndex: number) {
		super();
		this.borrowerIndex = borrowerIndex;
		this.sex = new DemographicsSex();
		this.race = new DemographicsRace();
		this.ethnicity = new DemographicsEthnicity();
	}

	public setField(demographicCategory: string, demographicName: string, customValue?: string): void {
		const settingInfo: DemographicsSectionKeyMap = this.demoMap.get(demographicCategory + demographicName);
		if (settingInfo) {
			this[settingInfo.section][settingInfo.key] = new DemographicAnswer((settingInfo.freeForm) ? customValue : true);
		}
	}

	public getDisplayForTruthyFields(): string[] {
		const demographics: string[] = [];
		const demoLookup = Lookups.DemographicsCheckOptions;
		_.forIn(this.sex, (demographic: DemographicAnswer<boolean>, key: string) => {
			if (demographic instanceof DemographicAnswer && demographic.value) {
				demographics.push(demoLookup[key].display);
			}
		});
		_.forIn(this.race, (demographic: DemographicAnswer<any>, key: string) => {
			if (demographic instanceof DemographicAnswer && demographic.value) {
				demographics.push(demoLookup[key].display || demographic.value);
			}
		});
		_.forIn(this.ethnicity, (demographic: DemographicAnswer<any>, key: string) => {
			if (demographic instanceof DemographicAnswer && demographic.value) {
				demographics.push(demoLookup[key].display || demographic.value);
			}
		});
		return demographics;
	}

	private createMapForDemographicsOptions(options: DemographicCheckOption[]): any {
		const optionsMap = new Map<string, DemographicsSectionKeyMap>();
		_.forEach(options, (entry: DemographicCheckOption, myKey: string) => {
			optionsMap.set(
				entry.compositeId.category + entry.compositeId.displayName,
				new DemographicsSectionKeyMap(entry.section, myKey, entry.freeForm)
			);
		});
		return optionsMap;
	}
}

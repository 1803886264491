import { TrackerEvent, ApplicationInsightsProviderMapping, ApplicationInsightsEvent } from '@nbkc/tracker';
import { ApplicationTrackerContext } from '../application-tracker-context';

export class PrivacyPolicyEvent extends TrackerEvent<ApplicationTrackerContext>
	implements ApplicationInsightsProviderMapping {
	public name: string = 'click-privacy-policy';

	public ai(): ApplicationInsightsEvent {
		const event = new ApplicationInsightsEvent();
		event.name = this.name;
		return event;
	}
}

export const ReferralSources = [
	'Advertisement',
	'Broker',
	'Employee Referral',
	'Existing Customer',
	'Mail',
    'nbkc.com',
    'Web Search',
    'Word of Mouth',
    'Other'
];
import { AdapterBase } from '@utilities/adapter-base';
import { LoanOfficer } from '@models/loan-officer.model';
import { ProfileApi } from '@models/api/profile.model';

export class ProfileApiToLoanOfficerAdapter extends AdapterBase<ProfileApi, LoanOfficer> {
	public adapt(source: ProfileApi): LoanOfficer {
		const loanOfficer = new LoanOfficer();
		loanOfficer.firstName = source.FirstName;
		loanOfficer.lastName = source.LastName;
		loanOfficer.loanOfficerMobilePhone = source.SecondaryPhone;
		loanOfficer.loanOfficerOfficePhone = source.PrimaryPhone;
		loanOfficer.loanOfficerEmail = source.EmailAddress;
		loanOfficer.contentType = source.ContentType;
		loanOfficer.nmlsNum = source.Nmls;
		loanOfficer.photo = source.Photo;
		return loanOfficer;
	}
}

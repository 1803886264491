import {
	TrackerEvent,
	ApplicationInsightsProviderMapping,
	GoogleAnalyticsProviderMapping,
	ApplicationInsightsEvent,
	GoogleAnalyticsEvent
} from '@nbkc/tracker';
import { ApplicationTrackerContext } from '../application-tracker-context';

export class LoCardHideEvent extends TrackerEvent<ApplicationTrackerContext>
	implements ApplicationInsightsProviderMapping, GoogleAnalyticsProviderMapping {
	public name: string = 'lo-card-hide';

	public constructor(public data: LoCardHideProps) {
		super();
	}

	public ai(): ApplicationInsightsEvent {
		const event = new ApplicationInsightsEvent();
		event.name = this.name;
		event.data = {
			email: this.data.loEmail
		};
		return event;
	}

	public ga(): GoogleAnalyticsEvent {
		return new GoogleAnalyticsEvent({
			category: 'Application',
			action: 'LO Card',
			label: 'Hide'
		});
	}
}

interface LoCardHideProps {
	loEmail: string;
}

import { Validatable, Validate, ValidateIf, Validators } from '@nbkc/validation';

export class ContactForm extends Validatable {
	public ApplicationContactId: string;
	@ValidateIf('this.hasContact', Validators.required())
	public name: string | null;
	@ValidateIf('this.hasContact', Validators.phoneNumber())
	public phone: string;
	public hasContact: boolean = null;
	@ValidateIf('this.hasContact', Validators.email())
	public email: string;
}

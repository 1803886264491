import { Component } from "@angular/core";

export abstract class CardRightCornerIconToken {}

@Component({
  selector: 'lib-card-right-corner-icon-component',
  templateUrl: './card-right-corner-icon.component.html',
  styleUrls: ['./card-right-corner-icon.component.scss'],
  providers: [
    {provide: CardRightCornerIconToken, useExisting: CardRightCornerIconComponent}
  ]
})
 export class CardRightCornerIconComponent extends CardRightCornerIconToken {
    constructor() {
        super();
    }
 }
import { Component } from '@angular/core';
import _ from 'lodash';
import { animate, style, transition, trigger } from '@angular/animations';
import { ITodoItem } from '@lookups/todo-items';

@Component({
	selector: 'app-left-menu',
	templateUrl: './left-menu.component.html',
	styleUrls: ['./left-menu.component.scss'],
	animations: [
		trigger('myInsertRemoveTrigger', [
			transition(':enter', [
				style({ transform: 'translateX(-280px)' }),
				animate('250ms', style({ transform: 'translateX(0)' })),
			]),
			transition(':leave', [
				animate('250ms', style({ transform: 'translateX(-280px)' }))
			])
		]),
	]
})
export class LeftMenuComponent {
	public todoItems: ITodoItem[] = 
	[
		{ name: 'Complete Application', isCompleted: true, isActive: false },
		{ name: 'Provide Documentation', isCompleted: false, isActive: true },
		{ name: 'Review & Sign Disclosures', isCompleted: false, isActive: false },
		{ name: 'Close Loan', isCompleted: false, isActive: false }
	];
	constructor(
	) { }
}

import { Component } from "@angular/core";

export abstract class CardContentToken {}

@Component({
  selector: 'lib-card-content',
  templateUrl: './card-content.component.html',
  styleUrls: ['./card-content.component.scss'],
  providers: [
    {provide: CardContentToken, useExisting: CardContentComponent}
  ]
})
export class CardContentComponent extends CardContentToken {

  constructor(){
    super();
  }
}
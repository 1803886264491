import { Sections } from '@lookups/sections';
import _ from 'lodash';
import { SectionProgress } from './section.progress.model';

export class SectionsProgress {
	[key: string]: SectionProgress;

	constructor() {
		_.forEach(Sections, (section) => {
			this[section.name] = new SectionProgress();
		});
	}
}

import {
	TrackerEvent,
	ApplicationInsightsProviderMapping,
	GoogleAnalyticsProviderMapping,
	ApplicationInsightsEvent,
	GoogleAnalyticsEvent
} from '@nbkc/tracker';
import { ApplicationTrackerContext } from '../application-tracker-context';

export class LoGridEditApplicationEvent extends TrackerEvent<ApplicationTrackerContext>
	implements ApplicationInsightsProviderMapping, GoogleAnalyticsProviderMapping {
	public name: string = 'lo-grid-edit-application';

	public ai(): ApplicationInsightsEvent {
		const event = new ApplicationInsightsEvent();
		event.name = this.name;
		return event;
	}

	public ga(): GoogleAnalyticsEvent {
		return new GoogleAnalyticsEvent({
			category: 'LO Grid',
			action: 'Click',
			label: 'Edit Application'
		});
	}
}

import { AuthService } from '@services/auth.service';
import { Router } from '@angular/router';
import { Component } from '@angular/core';

@Component({
	template: ``,
	styles: []
})
export class LoginComponent {
	public constructor(authService: AuthService, router: Router) {
		if (window.location.search !== '') {
			authService.handleAuthCallback().subscribe((url) => {
				router.navigate([url]);
			}, () => {
				router.navigate(['/']);
			});
		} else {
			router.navigate(['/']);
		}

	}
}

import { PreApprovalApi } from "@models/api/borrower-portal/pre-approval";
import { PreApprovalForm } from "@models/form/borrower-portal/pre-approval.model";
import { AdapterBase } from "@utilities/adapter-base";

export class PreApprovalFormToPreApprovalApiAdapter extends AdapterBase<PreApprovalForm, PreApprovalApi> {
	private targetTextMaskRegex: RegExp = /[^0-9\.-]+/g;
	adapt(source: PreApprovalForm): PreApprovalApi {
		const dest = new PreApprovalApi();
		dest.Name = 'pre-approval-documentation';
		dest.PurchasePrice = Number(source.salesPrice.replace(this.targetTextMaskRegex,""));
		dest.LoanAmount = source.loanAmount;
		dest.LoanToValue = source.loanToValue;

		return dest;
	}
}
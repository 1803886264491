import { MilitaryStates } from '@lookups/military-states';
import { ApplicationPurposes } from './application-purposes';
import { AssetTypes } from './asset-types';
import { DependentChoices } from './dependent-choices';
import { IncomeTypes } from './income-types';
import { MaritalStatuses } from './marital-statuses';
import { PropertyTitleTypes } from './property-title-types';
import { PropertyUsageTypes } from './property-usage-types';
import { LoanApplicationStateList, States } from './states';
import { AvailableTextMasks, TextMasks } from './text-masks';
import { UnitTypes } from './unit-types';
import { DemographicsCheckOptions } from './demographics-check-options';
import { Lookable } from '@lookups/lookable';
import { ReferralSources } from './referral-sources';

export class Lookups extends Lookable {
	public static AssetTypes: IdDescriptionPair[] = AssetTypes;
	public static DemographicsCheckOptions: any = DemographicsCheckOptions;
	public static DependentChoices: IdDescriptionPair[] = DependentChoices;
	public static ApplicationPurposes: IdDescriptionPair[] = ApplicationPurposes;
	public static IncomeTypes: IdDescriptionPair[] = IncomeTypes;
	public static MaritalStatuses: IdDescriptionPair[] = MaritalStatuses;
	public static PropertyTitleTypes: any = PropertyTitleTypes;
	public static PropertyUsageTypes: IdDescriptionPair[] = PropertyUsageTypes;
	public static States: LoanApplicationStateList[] = States;
	public static MilitaryStates: any = MilitaryStates;
	public static TextMasks: AvailableTextMasks = TextMasks;
	public static UnitTypes: IdDescriptionPair[] = UnitTypes;
	public static ReferralSources: string[] = ReferralSources;
}

export interface IdDescriptionPair {
	id: string | number | null;
	description: string;
}

import { DocumentRow } from './form/document-row.model';
import { LoanApplicationApi } from './api/loan-application';

export interface IApplicationView {
	ApplicationId: string;
	PortfolioId: string; 
	ApplicationPurpose: string;
	ApplicationStatus: string;
	ApplicationStatusId: number;
	BorrowerCellPhone: string;
	BorrowerEmail: string;
	BorrowerHomePhone: string;
	BorrowerId: string;
	BorrowerName: string;
	CreatedDt: Date | null;
	LoanNumber: string;
	LoanOfficerId: string;
	LoanOfficerName: string;
	Progress: string;
	RequestedAmount: string;
	RequestedDownPayment: string;
	SubjectPropertyAddress: string | null;
	SubjectPropertyCity: string | null;
	SubjectPropertyState: string | null;
	SubjectPropertyZip: string | null;
	SubmittedDt?: Date | null;
	UserId: string;
	UserName: string;
	DocumentsReceived: number;
	Documents: DocumentRow[];
	apiLoan: LoanApplicationApi;
}

export class ApplicationView implements IApplicationView {
	public ApplicationId: string;
	public PortfolioId: string;
	public ApplicationPurpose: string;
	public ApplicationStatus: string;
	public ApplicationStatusId: number;
	public BorrowerCellPhone: string;
	public BorrowerEmail: string;
	public BorrowerHomePhone: string;
	public BorrowerId: string;
	public BorrowerName: string;
	public CreatedDt: Date | null;
	public LoanNumber: string;
	public LoanOfficerId: string;
	public LoanOfficerName: string;
	public ModifiedDt: Date | null;
	public Progress: string;
	public RequestedAmount: string;
	public RequestedDownPayment: string;
	public SubjectPropertyAddress: string | null;
	public SubjectPropertyCity: string | null;
	public SubjectPropertyState: string | null;
	public SubjectPropertyZip: string | null;
	public SubjectPropertyCounty: string | null;
	public SubjectPropertyCountryCode: string | null;
	public SubmittedDt: Date | null;
	public UserId: string;
	public UserName: string;
	public DocumentsReceived: number;
	public Documents: DocumentRow[];
	public apiLoan: LoanApplicationApi;
}

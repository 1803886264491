import { HttpClient } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Tracker } from "@nbkc/tracker-angular";
import { AuthService } from "./auth.service";
import { ConfigService } from "./config.service";
import { ErrorService } from "./error.service";
import { catchError } from "rxjs/internal/operators/catchError";
import { LoanOfficerProfileFailedError } from "../tracking/errors/loan-officer-profile-failed.error";
import { Observable, of } from "rxjs";
import { LoanOfficer } from "@models/loan-officer.model";
import { first, map } from "rxjs/operators";
import { ProfileApiToLoanOfficerAdapter } from "../adapters/api-to-form/profile-api-to-loan-officer.adapter";
import { LoanOfficerProfileIncompleteEvent } from "../tracking/events";
import { ApplicationPurposes } from "@constants";

@Injectable({
	providedIn: 'root'
})
export class LoanOfficerService {
    private _profileApiToLoanOfficerAdapter: ProfileApiToLoanOfficerAdapter = new ProfileApiToLoanOfficerAdapter();

	constructor(
		public http: HttpClient,
		public authService: AuthService,
		public config: ConfigService,
		public injector: Injector,
		public tracker: Tracker,
        public error: ErrorService,
	) {
	}

	public getLoanOfficer(loanOfficerId?: string, loanPurpose? : string): Observable<LoanOfficer> {
       if (loanOfficerId) {
			return this.getUserById(loanOfficerId).pipe(
				map((response) => {
					if (response.Success) {
						if (
							response.Data.FirstName &&
							response.Data.LastName &&
							(response.Data.PrimaryPhone || response.Data.SecondaryPhone || response.Data.EmailAddress)
						) {
							return this._profileApiToLoanOfficerAdapter.adapt(response.Data);
						} else {
							this.tracker.event(new LoanOfficerProfileIncompleteEvent({ response }));
							return this.getDefaultLoanOfficer(loanPurpose);
						}
					} else {
						this.tracker.error(new LoanOfficerProfileFailedError(new Error(JSON.stringify(response))));
						return this.getDefaultLoanOfficer(loanPurpose);
					}
				}),
				catchError((error) => {
					this.tracker.error(new LoanOfficerProfileFailedError(error));
					return of(this.getDefaultLoanOfficer(loanPurpose));
				})
			);
		} else {
			return of(this.getDefaultLoanOfficer(loanPurpose));
		}
	}

	public getDefaultLoanOfficer(loanPurpose? : string): LoanOfficer {
		const defaultLoanOfficer = new LoanOfficer();
		defaultLoanOfficer.firstName = 'nbkc';
		defaultLoanOfficer.lastName = 'bank';
		defaultLoanOfficer.loanOfficerEmail = 'apply.now@nbkc.com';
		defaultLoanOfficer.nmlsNum = '409631';

		if(loanPurpose && loanPurpose == ApplicationPurposes.heloc){
			defaultLoanOfficer.loanOfficerEmail = 'consumerlending@nbkc.com';
		}
		return defaultLoanOfficer;
	}

	public getUserById(userId: string): Observable<any> {
		return this.http.get(this.config.MTG_API_DOMAIN + '/api/user/profile?userId=' + userId).pipe(first());
	}
}

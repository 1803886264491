import { Injectable } from '@angular/core';
import { LoanOfficerSummaryApi } from '../models/api/loan-officer-summary.model';
import { Observable, Subject } from 'rxjs';
import $ from 'jquery';

@Injectable({
	providedIn: 'root'
})
export class AppState {
	public loanOfficerCardShown: boolean;

	private _ready: boolean = false;
	private _readyTimeout: number = 1500;
	private _pendingReady: number = 0;
	private _readyTimer: any = null;
	private _loading: boolean = false;
	private _loadingTimeout: number = 50;
	private _pendingLoad: number = 0;
	private _loadingTimer: any = null;
	private _loadingMessage: string;
	private _delayLoadingTimer: boolean = true;
	private _subject: Subject<string> = new Subject();
	public loanOfficers: LoanOfficerSummaryApi[];

	constructor() {
		this.notReady();
		this.startReadyTimer();
		this.startLoadingTimer();
		this.ready();
	}

	public getSelectedLoanOfficer(): Observable<string> {
		return this._subject.asObservable();
	}

	public loanOfficerSelected(loanOfficerId: string): void {
		this._subject.next(loanOfficerId);
	}

	public loading(status: string): void {
		const wasLoading: any = this._loading;

		this._pendingLoad++;

		if (status && status.length > 0) {
			this._loadingMessage = status;
		}

		if (!wasLoading) {
			if (this._loadingTimer) {
				clearInterval(this._loadingTimer);
			}

			this._loadingTimer = setInterval(this.loadingTimer.bind(this), this._loadingTimeout);
		}
	}

	public notLoading(): void {
		this._pendingLoad--;
	}

	public get loadingMessage(): string {
		return this._loadingMessage;
	}

	public get isLoading(): boolean {
		return this._loading;
	}

	public loadingTimer(): void {
		this._loading = this._pendingLoad > 0;
		if (this._loading && this._delayLoadingTimer) {
			clearInterval(this._loadingTimer);
			setTimeout(() => {
				this._loadingTimer = setInterval(this.loadingTimer.bind(this), this._loadingTimeout);
			}, 1000);
			this._delayLoadingTimer = false;
		} else if (!this._loading) {
			this._delayLoadingTimer = true;
		}
	}

	public startLoadingTimer(): void {
		if (!this._loadingTimer) {
			this._loadingTimer = setInterval(this.loadingTimer.bind(this), this._loadingTimeout);
		}
	}

	public ready(): void {
		this._pendingReady--;
	}

	public notReady(): void {
		this._pendingReady++;
	}

	public get isReady(): boolean {
		return this._ready;
	}

	public readyTimer(): void {
		if (!this._ready) {
			this._ready = this._pendingReady <= 0;
			if (this._ready) {
				$('#nbkc-core-app-loader').fadeOut(1000);
				clearInterval(this._readyTimer);
			}
		}
	}

	public startReadyTimer(): void {
		if (!this._readyTimer) {
			this._readyTimer = setInterval(this.readyTimer.bind(this), this._readyTimeout);
		}
	}

}

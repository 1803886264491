import { Component, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoanOfficer } from '@models/loan-officer.model';
import { Tracker } from '@nbkc/tracker-angular';
import { AppState } from '@services/app-state.service';
import { ConfigService } from '@services/config.service';
import { ErrorService } from '@services/error.service';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { PaymentSubmissionEvent } from 'src/app/shared/tracking/events/payment-submission.event';
import { PortfolioService } from '@services/borrower-portal/portfolio.service';
import { PortfolioRequestTypes } from 'src/app/shared/constants/borrower-portal/portfolio-request-types';
import { PortfolioRequestStatuses } from 'src/app/shared/constants/borrower-portal/portfolio-request-statuses';
import { BorrowerPortalState } from '@services/borrower-portal/borrower-portal-state.service';
import { BorrowerPortalCalculationService } from '@services/borrower-portal/borrower-portal-calculation.service';
import { LineItem } from '@models/api/borrower-portal/line-item';
import { PortfolioRequestForm } from '@models/form/borrower-portal/portfolio-request-form.model';
import { Observable } from 'rxjs/internal/Observable';
import { LoanOfficerService } from '@services/loan-officer.service';
import { PortfolioForm } from '@models/form/borrower-portal/portfolio-form.model';

@Component({
	selector: 'app-borrower-portal',
	templateUrl: './borrower-portal.component.html',
	styleUrls: ['./borrower-portal.component.scss']
})
export class BorrowerPortalComponent implements OnInit, OnDestroy {
	public loanOfficer: LoanOfficer;
	public preApprovalRequest: PortfolioRequestForm;
	public eSignRequests: PortfolioRequestForm[] = [];
	public filteredESignRequest: PortfolioRequestForm;
	public paymentRequest: PortfolioRequestForm;
	public isPaymentWidgetEnabled: boolean = false;
	public isDocMagicWidgetEnabled: boolean = false;
	public showESignDialog: boolean = false
	public showPaymentDialog: boolean = false;
	public documentRequests: PortfolioRequestForm[] = [];

	// Shared component
	public isButtonDisabled: boolean = false;
	public parentEmitter = new EventEmitter<any>();
	public isLoading: boolean = false;
	public isCardExpanded: boolean = false;
	public downloadDocument: boolean = false;

	constructor(
		public appState: AppState,
		public errorService: ErrorService,
		public router: Router,
		public route: ActivatedRoute,
		public config: ConfigService,
		public tracker: Tracker,
		public portfolioService: PortfolioService,
		public portalState: BorrowerPortalState,
		public borrowerPortalCalculationService: BorrowerPortalCalculationService,
		public loanOfficerService: LoanOfficerService,
	) {

	}

	ngOnInit(): void {
		this.portfolioService.isLetterDownloading$.subscribe((isLoading) => {
			this.isLoading = isLoading
		})
		if (this.config.PAYMENT_FEATURE_FLAG_ENABLED?.toLowerCase() === 'true') {
			this.isPaymentWidgetEnabled = true;
		}
		if (this.config.DOCMAGIC_FEATURE_FLAG_ENABLED?.toLowerCase() === 'true') {
			this.isDocMagicWidgetEnabled = true;
		}
		this.appState.notReady();
		this.appState.loading('Loading your application');
		this.initializePortfolio();
	}

	initializePortfolio(): void {
		this.portfolioService.getPortfolio(this.route.snapshot.params.id).pipe(
			tap((response: PortfolioForm) => {
				this.setRequestValues(response.requests);
			}),
			switchMap((portfolio: PortfolioForm) => {
				return this.getLoanOfficer(portfolio.internalContact?.userId).pipe(
				);
			}),
		).subscribe((lo: LoanOfficer) => {
			this.loanOfficer = lo;
			this.appState.notLoading();
			this.appState.ready();
		}, () => {
			this.appState.notLoading();
			this.appState.ready();
		})
	}

	getBadgeContent(request: PortfolioRequestForm): number {
		return request.detail.Packages.filter(p => p.Status === 'InProgress').length
	}

	isPaymentRequired() {
		let isPaymentRequired = !this.portfolioService.isPaymentComplete &&
			!!this.paymentRequest?.detail &&
			this.paymentRequest.detail.TotalAmount - (this.paymentRequest.detail.AmountPaid ?? 0) > 0;

		return isPaymentRequired || this.portfolioService.showPaymentDialog;
	}

	showPaymentRequestDialog() {
		this.portfolioService.showPaymentDialog = true;
		this.tracker.event(new PaymentSubmissionEvent(
			{
				loanId: this.paymentRequest.scopeId,
				amount: this.paymentRequest?.detail?.TotalAmount?.toString(),
				description: "User opened the payment modal"
			}
		));
	}

	onPaymentRequestDialogVisibilityChange(isVisible) {
		if (isVisible === false) {
			this.portfolioService.hidePaymentMessages();
			this.portfolioService.resetPaymentForm();
		}
		if (this.portfolioService.isPaymentComplete) {
			this.paymentRequest = null;
			this.portfolioService.isPaymentComplete = false;
		}
	}

	displayESignDialog(request: PortfolioRequestForm) {
		this.filteredESignRequest = request;
		this.showESignDialog = !this.showESignDialog;
	}

	expandToggleButtonClicked() {
		this.portalState.preApprovalFormShown = !this.isCardExpanded;
		if (!this.portalState.preApprovalFormShown) {
			this.portfolioService.preApprovalLetter.loanAmount = this.preApprovalRequest.detail.MaxLoanAmount;
			this.portfolioService.preApprovalLetter.loanToValue = Number(Math.floor(this.preApprovalRequest.detail.MaxLoanToValue).toFixed());
			this.portfolioService.preApprovalLetter.salesPrice = this.preApprovalRequest.detail.PurchasePrice.toFixed().toString();
		}

		this.isCardExpanded = !this.isCardExpanded;
	}

	async rightActionButtonClicked() {
		await this.portfolioService.downloadPreApprovalLetter();
	}

	disableButtonToggle(isButtonDisabled) {
		this.isButtonDisabled = isButtonDisabled;
	}

	ngOnDestroy(): void {
		this.errorService.dismiss();
	}

	getLoanOfficer(loanOfficerId: string = ""): Observable<LoanOfficer> {
		return this.loanOfficerService.getLoanOfficer(loanOfficerId).pipe(
			map((result: LoanOfficer) => {
				return result;
			}
			))
	}

	private setRequestValues(requestForms: PortfolioRequestForm[]): void {
		const inProgressRequests = requestForms.filter(x => x.status === PortfolioRequestStatuses.inProgress);
		inProgressRequests.forEach((request: PortfolioRequestForm) => {
			switch (request.type) {
				case PortfolioRequestTypes.preApproval:
					this.preApprovalRequest = request;
					this.portfolioService.preApprovalLetter.loanAmount = this.borrowerPortalCalculationService.getCalculatedLoanAmount(request.detail.PurchasePrice, request.detail.MaxLoanToValue, request.detail.MaxLoanAmount);
					this.portfolioService.preApprovalLetter.loanToValue = this.borrowerPortalCalculationService.getCalculatedLoanToValue(request.detail.PurchasePrice, request.detail.MaxLoanToValue, request.detail.MaxLoanAmount);
					this.portfolioService.preApprovalLetter.salesPrice = request.detail.PurchasePrice.toString();
					this.portfolioService.preApprovalLetter.loanId = request.scopeId;
					break;
				case PortfolioRequestTypes.electronicSignatures:
					this.eSignRequests.push(request);
					break;
				case PortfolioRequestTypes.payment:
					if (!!request.detail) {
						this.portfolioService.isPaymentComplete = false;
						this.paymentRequest = request;
						this.portfolioService.payment.amount = request.detail.TotalAmount;
						this.portfolioService.payment.requestId = request.id;
						const lineItems: LineItem[] = [];
						request.detail.LineItems.forEach((li: LineItem) => {
							const lineItem = new LineItem();
							lineItem.Description = li.Description;
							lineItem.Amount = li.Amount;
							lineItems.push(lineItem);
						});
						this.portfolioService.payment.lineItems = lineItems;
					}
					break;
				case PortfolioRequestTypes.documentation:
					if (!!request.detail) {
						this.documentRequests.push(request);
					}
					break;
				default:
					break;
			}
		})
	}
}

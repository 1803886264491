import { AdapterBase, AdapterOptions } from '@utilities/adapter-base';
import { DemographicApi } from '@models/api/demographic';
import { BorrowerDemographicForm } from '@models/form/demographics/borrower-demographics.model';
import _ from 'lodash';

export class DemographicApiCollectionToBorrowerDemographicFormAdapter extends AdapterBase<DemographicApi[],	BorrowerDemographicForm> {
	public adapt(source: DemographicApi[], options: CurrentAdapterOptions): BorrowerDemographicForm {
		const demographic = new BorrowerDemographicForm(options.borrowerIndex);
		_.forEach(source, (borrowerDemographic) => {
			demographic.setField(
				borrowerDemographic.DemographicCategory,
				borrowerDemographic.DemographicName,
				borrowerDemographic.CustomText
			);
		});
		return demographic;
	}
}

interface CurrentAdapterOptions extends AdapterOptions {
	borrowerIndex: number;
}

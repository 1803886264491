import { IdentifiersApi } from './identifiers';
import { BorrowerApi } from './borrower';
import { AssetApi } from './asset';
import { LoanDetailsApi } from './loan-details';
import { SubjectPropertyApi } from './subject-property';
import { ReoPropertyApi } from './reo-property';
import { ContactApi } from './contact';
import { LoanOfficerSummaryApi } from './loan-officer-summary.model';
import { BorrowerRequestApi } from './borrower-request';
import { BorrowerConsent } from './borrower-consent';
import { LinkedAccountApi } from './linked-account';

export class LoanApplicationApi {
	public Identifiers: IdentifiersApi;
	public Borrowers: BorrowerApi[];
	public Assets: AssetApi[];
	public BorrowerConsents: [BorrowerConsent];
	public RequestedLoanDetails: LoanDetailsApi; // pre submit
	public LinkedAccounts: LinkedAccountApi[];
	public HasVaEligibleBorrower: boolean;
	public LoanDetails: LoanDetailsApi; // post submit
	public SubjectProperty: SubjectPropertyApi;
	public LoanOfficer: LoanOfficerSummaryApi | null;
	public ApplicationStatus: string;
	public Realtor: boolean;
	public ApplicationProgress: string;
	public UrlaVersion: string;
	public ApplicationContacts: ContactApi[];
	public CreatedDate: string;
	public SubmittedDate: string;
	public LastModifiedDate: string;
	public ReoProperties: ReoPropertyApi[];
	public BorrowerRequests: BorrowerRequestApi[];
	public Source: string;
}

import { SectionsProgress } from './sections.progress.model';

export class FormProgress {
	public reviewed: boolean;
	public sections: SectionsProgress;

	constructor(props: any = { }) {
		this.reviewed = props.reviewed || false;
		this.sections = props.sections || new SectionsProgress();
	}
}

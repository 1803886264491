export const MilitaryStates = [{
	StateId: 52,
	Name: 'Armed Forces Americas',
	StateCode: 'AA'
}, {
	StateId: 53,
	Name: 'Armed Forces Pacific',
	StateCode: 'AP'
}, {
	StateId: 54,
	Name: 'Armed Forces Europe',
	StateCode: 'AE'
}];

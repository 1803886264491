import { TrackerEvent, ApplicationInsightsProviderMapping, ApplicationInsightsEvent } from '@nbkc/tracker';
import { ApplicationTrackerContext } from '../application-tracker-context';

export class AuthGuardSuccessEvent extends TrackerEvent<ApplicationTrackerContext>
	implements ApplicationInsightsProviderMapping {
	public name: string = 'auth-guard-success';

	public ai(): ApplicationInsightsEvent {
		const event = new ApplicationInsightsEvent();
		event.name = this.name;
		return event;
	}
}

import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
	selector: '[clearInvalidDate]'
})
export class ClearInvalidDateDirective {
	@Input() clearInvalidDate: string;
	constructor(public el: ElementRef, public ngModel: NgModel) {
	}

	@HostListener('blur')
	public blur(): void {
		if (this.el.nativeElement.value && this.el.nativeElement.value.replace(/[^0-9]/g, '').length !== 8) {
			this.ngModel.reset(null);
		}
	}
}

import { AdapterBase } from '@utilities/adapter-base';
import { BorrowerForm } from '@models/form/borrower.model';
import { BorrowerApi } from '@models/api/borrower';
import { PhoneApi } from '@models/api/phone';
import { EmailApi } from '@models/api/email';
import _ from 'lodash';
import moment from 'moment';
import { ConfigurationValues } from '@constants';
import { MilitaryServiceApi } from '@models/api/military-service';
import { IncomeSourceApi } from '@models/api/income-source';

export class BorrowerFormToBorrowerApiAdapter extends AdapterBase<BorrowerForm, BorrowerApi> {
	adapt(source: BorrowerForm): BorrowerApi {
		const dest: BorrowerApi = new BorrowerApi();
		dest.BorrowerIndex = source.index;
		dest.FirstName = source.firstName;
		dest.LastName = source.lastName;
		dest.MiddleName = source.middleInitial;
		dest.Suffix = source.suffix;
		dest.TaxIdentificationIdentifier = source.ssn;
		dest.BirthDate = this.verifyAndReturnDate(source.birthDate);
		dest.DependentCount = source.depCount;
		dest.HaveEmployers = source.haveEmployers;
		dest.MaritalStatus = source.maritalStatus;
		if (source.depAges && source.depAges.length > 0) {
			dest.DependentAges = _.map(source.depAges, (value) => {
				return value.age;
			}).join(',');
		}
		dest.Phones = (source.primaryPhone) ? [new PhoneApi(source.primaryPhone, true)] : null;
		dest.Emails = (source.email) ? [new EmailApi(source.email, true)] : null;
		dest.MilitaryService = new MilitaryServiceApi();
		dest.MilitaryService.SelfDeclaredMilitaryService = source.militarySelfDeclaredService;
		dest.MilitaryService.ActiveDuty = source.militaryActiveDuty;
		dest.MilitaryService.ExpectedCompletionDate = this.verifyAndReturnDate(source.militaryExpectedCompletionDate);
		dest.MilitaryService.Veteran = source.militaryVeteran;
		dest.MilitaryService.ReserveNationalGuardReserveActivated = source.militaryReserveNationalGuardReserveActivated;
		dest.MilitaryService.SurvivingSpouse = source.militarySurvivingSpouse;

		return dest;
	}

	private verifyAndReturnDate(date: string): string | null {
		if (date && moment(date, ConfigurationValues.dateFormat, true).isValid()) {
			return date;
		} else {
			return null;
		}
	}
}

import {
	TrackerEvent,
	ApplicationInsightsProviderMapping,
	ApplicationInsightsEvent
} from '@nbkc/tracker';
import { ApplicationTrackerContext } from '../application-tracker-context';

export class PaymentSubmissionEvent extends TrackerEvent<ApplicationTrackerContext>
	implements ApplicationInsightsProviderMapping {
	public name: string = 'payment-submission';

	public constructor(public data: PaymentSubmissionEventProps) {
		super();
	}

	public ai(): ApplicationInsightsEvent {
		const event = new ApplicationInsightsEvent();
		event.name = this.name;
		event.data = {
			applicationId: this.trackerContext.applicationId,
			loanId: this.data.loanId,
			salesPrice: this.data.amount
		};

		return event;
	}
}

interface PaymentSubmissionEventProps {
	loanId: string;
	amount: string;
	description: string;
}

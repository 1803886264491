import { Injectable } from "@angular/core";

@Injectable({
    providedIn:'root'
})
export class BorrowerPortalCalculationService {

	public getCalculatedLoanToValue(salesPriceInt: number, maxLoanToValue: number, maxLoanAmount: number): number {
		if ((salesPriceInt * (maxLoanToValue / 100)) < maxLoanAmount) {
			return maxLoanToValue;
		} else {
			return parseInt(((maxLoanAmount / salesPriceInt) * 100).toFixed());
		}
	}

	public getCalculatedLoanAmount(salesPriceInt: number, maxLoanToValue: number, maxLoanAmount: number): number {
		if ((salesPriceInt * (maxLoanToValue / 100)) > maxLoanAmount) {
			return maxLoanAmount;
		} else {
			return parseInt((salesPriceInt * (maxLoanToValue  / 100)).toFixed());
		}
	}
}
import { AdapterBase } from '@utilities/adapter-base';
import { AssetApi } from '@models/api/asset';
import { AccountForm } from '@models/form/account.model';
import numeral from 'numeral';
import _ from 'lodash';

export class AccountFormToAssetApiAdapter extends AdapterBase<AccountForm, AssetApi> {
	adapt(source: AccountForm): AssetApi {
		const asset = new AssetApi();
		asset.AssetType = source.assetType;
		asset.ContainsGift = source.gift;
		asset.Value = numeral(source.amount).value();
		asset.NameOfHolder = source.name;
		asset.BorrowersWithInterestInAsset = _.map(source.holders, (index: number) => {
			return index;
		});
		return asset;
	}
}

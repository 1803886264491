import { AdapterBase, AdapterOptions } from '@utilities/adapter-base';
import { ResidenceApi } from '@models/api/residence';
import { ResidenceForm } from '@models/form/residence.model';
import { AddressApiToAddressFormAdapter } from './address-api-to-address-form.adapter';
import { NumberHelper } from '@utilities/helpers';

export class ResidenceApiToResidenceFormAdapter extends AdapterBase<ResidenceApi, ResidenceForm> {
	private addressAdapter: AddressApiToAddressFormAdapter = new AddressApiToAddressFormAdapter();

	public adapt(source: ResidenceApi, options: CurrentAdapterOptions): ResidenceForm {
		const dest = new ResidenceForm();
		dest.address = this.addressAdapter.adapt(source.Address);
		dest.mailingAddress = this.addressAdapter.adapt(source.MailingAddress);
		dest.mailingAddress.requireCounty = false;
		dest.duration = source.DurationTermMonths;
		dest.isOwned = source.PropertyOwned;
		dest.isCurrent = source.IsCurrent;
		dest.mailingAddressSameAsResidence = !source.MailingAddressDifferent;
		dest.rentAmount = NumberHelper.numberToString(source.RentalAmount);
		dest.borrowerIndexes = [options.borrowerIndex];
		return dest;
	}
}

interface CurrentAdapterOptions extends AdapterOptions {
	borrowerIndex: number;
}

<div class="main-loader">
	<div class="nbkc-loader-wrap internal" [ngClass]="{'nbkc-visible' : appState.isLoading && appState.isReady }">
		<div class="nbkc-loader nbkc-loader-centered">
			<div class="nbkc-loader-body">
				<div class="nbkc-bike blue"></div>
				<div class="nbkc-loader-status" *ngIf="appState.loadingMessage">
					<p class="saving">{{appState.loadingMessage }}<span>.</span><span>.</span><span>.</span></p>
				</div>
			</div>
			<div class="nbkc-loader-block"></div>
		</div>
	</div>
</div>

import { AdapterBase } from '@utilities/adapter-base';
import { PortfolioRelationship } from '@models/api/borrower-portal/portfolio-relationship';
import { PortfolioRelationshipForm } from '@models/form/borrower-portal/portfolio-relationship-form.model';

export class PortfolioRelationshipToPortfolioUserAdapter extends AdapterBase<PortfolioRelationship, PortfolioRelationshipForm> {
	public adapt(source: PortfolioRelationship): PortfolioRelationshipForm {

            const relationshipForm = new PortfolioRelationshipForm();
            relationshipForm.firstName = source.FirstName;
            relationshipForm.lastName = source.LastName;
            relationshipForm.primaryPhoneNumber = source.Phones?.find(x => x.IsPrimary).Number;
            relationshipForm.emailAddress = source.Emails?.find(x => x.IsPrimary).Address; 
            relationshipForm.nmls = source.Nmls?.toString();
            relationshipForm.role = source.Role;
            relationshipForm.userId = source.UserId;

		    return relationshipForm;
	}
}

import { PortfolioApi } from "@models/api/borrower-portal/portfolio-api";
import { PortfolioForm } from '@models/form/borrower-portal/portfolio-form.model';
import { AdapterBase } from "@utilities/adapter-base";
import { PortfolioRequestToPortfolioRequestFormAdapter } from "./portfolio-request-to-portfolio-request-form";
import { PortfolioRelationshipToPortfolioUserAdapter } from "./portfolio-relationship-to-portfolio-user";

export class PortfolioApiToPortfolioFormAdapter extends AdapterBase<PortfolioApi, PortfolioForm> {
    public adapt(portfolioApi : PortfolioApi) : PortfolioForm {
        let portfolio = new PortfolioForm(); 
        portfolio.id = portfolioApi.Id; 
        portfolio.status = portfolioApi.Status;

        let portfolioRequestToPortfolioRequestFormAdapter = new PortfolioRequestToPortfolioRequestFormAdapter(); 
        portfolio.requests = portfolioRequestToPortfolioRequestFormAdapter.adaptCollection(portfolioApi.Requests); 
        let portfolioRelationshipToPortfolioUserAdapter = new PortfolioRelationshipToPortfolioUserAdapter(); 
        portfolio.relationships = portfolioRelationshipToPortfolioUserAdapter.adaptCollection(portfolioApi.Relationships);
        if(portfolioApi.InternalContact){
            portfolio.internalContact = portfolioRelationshipToPortfolioUserAdapter.adapt(portfolioApi.InternalContact);
        }
        return portfolio; 
    }
}
import { Component, Input} from "@angular/core";

export abstract class IconToken {}

@Component({
  selector: 'lib-icon-component',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  providers: [
    {provide: IconToken, useExisting: IconComponent}
  ]
})
 export class IconComponent extends IconToken {
  @Input() iconClass: string;
    constructor() {
        super();
    }
 }
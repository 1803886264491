import { Injectable } from '@angular/core';
import createAuth0Client, { RedirectLoginResult } from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { ConfigService } from '@services/config.service';
import _ from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import { forkJoin, from, Observable, of, throwError, Subject } from 'rxjs';
import { catchError, concatMap, map, share, shareReplay, tap, takeUntil } from 'rxjs/operators';
import { CacheService } from './cache.service';
import { CacheKeys } from '@constants';
import { ApplicationTrackerContext } from '../tracking/application-tracker-context';
import { AuthRefreshEvent } from '../tracking/events';
import { SendToLoginEvent } from '../tracking/events/send-to-login.event';
import { Tracker } from '@nbkc/tracker-angular';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	public getAuthDetails$: Observable<BasicAuthInfo>;
	public loggedIn: boolean = null;
	private auth0Client$: Observable<Auth0Client>;
	private handleRedirectCallback$: Observable<RedirectLoginResult>;
	private isContextSet: boolean;
	private dispose$: Subject<any> = new Subject<any>();

	constructor(
		public config: ConfigService,
		public cookieService: CookieService,
		public cacheService: CacheService,
		public tracker: Tracker
	) { }

	public start(): Promise<void> {
		const urlParams = new URLSearchParams(window.location.search);
		const themeParam = urlParams.get('theme');
		const emailParam = urlParams.get('email');
		const applicationType = urlParams.get('applicationType')
		const email = emailParam ? emailParam : '';
		const theme = themeParam ? themeParam : '';
		const purpose = applicationType ? applicationType : '';
		this.cacheService.set(CacheKeys.requestedApplicationType, purpose); 
		return new Promise((resolve, reject) => {
			try {
				const auth0Options = {
					domain: this.config.AUTH0_DOMAIN,
					client_id: this.config.AUTH0_CLIENT_ID,
					redirect_uri: `${window.location.origin}/login`,
					scope: 'openid profile email loanapp:customer',
					audience: this.config.AUTH0_AUDIENCE,
					theme,
					email
				};

				this.auth0Client$ = (from(
					createAuth0Client(auth0Options)
				) as Observable<Auth0Client>).pipe(
					shareReplay(1),
					catchError((err) => throwError(err)),
					takeUntil(this.dispose$)
				);

				const getTokenSilently$ = this.auth0Client$.pipe(
					concatMap((client: Auth0Client) =>
						from(client.getTokenSilently())
					),
					share()
				);

				const getUser$ = this.auth0Client$.pipe(
					concatMap((client: Auth0Client) => from(client.getUser())),
					share()
				);

				this.handleRedirectCallback$ = this.auth0Client$.pipe(
					concatMap((client) => from(client.handleRedirectCallback()))
				);

				this.getAuthDetails$ = getTokenSilently$.pipe(
					concatMap((response) => {
						return forkJoin({
							token: of(response),
							isLoggedIn: of(true),
							user: getUser$
						});
					}),
					catchError((error) => {
						return of({
							token: null,
							isLoggedIn: false,
							user: null
						});
					}),
					tap((auth) => {
						this.loggedIn = auth.isLoggedIn;
						if (!this.isContextSet) {
							this.cleanUpAuth0Cookies();
							this.setUpUserContextForLogging(auth.user);
						}
					})
				);
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	public handleAuthCallback(): Observable<RedirectLoginResult> {
		let targetRoute;
		return this.handleRedirectCallback$.pipe(
			tap((cbRes) => {
				targetRoute =
					cbRes.appState && cbRes.appState.target
						? cbRes.appState.target
						: '/';

				if (cbRes.appState) {
					if (cbRes.appState.lastLoanOfficer) {
						this.cacheService.set(
							CacheKeys.lastLoanOfficer,
							cbRes.appState.lastLoanOfficer
						);
					}
					if (cbRes.appState.interactionTracking) {
						this.cacheService.set(
							CacheKeys.interactionTracking,
							cbRes.appState.interactionTracking
						);
					}
				}
			}),
			map(() => {
				return targetRoute;
			})
		);
	}

	public login(redirectPath: string = '/'): void {
		this.tracker.event(new SendToLoginEvent());
		this.auth0Client$.subscribe((client: Auth0Client) => {
			this.tracker.flushAllProviders();

			const appState: any = {
				target: redirectPath
			};

			if (this.cacheService.get(CacheKeys.lastLoanOfficer)) {
				appState.lastLoanOfficer = this.cacheService.get(
					CacheKeys.lastLoanOfficer
				);
			}

			if (this.cacheService.get(CacheKeys.interactionTracking)) {
				appState.interactionTracking = this.cacheService.get(
					CacheKeys.interactionTracking
				);
			}

			client.loginWithRedirect({
				redirect_uri: `${window.location.origin}/login`,
				appState
			});
		});
	}

	public logout(): void {
		this.auth0Client$.subscribe((client: Auth0Client) => {
			this.tracker.flushAllProviders();
			client.logout({
				client_id: this.config.AUTH0_CLIENT_ID,
				returnTo: `${window.location.origin}`
			});
		});
	}

	public async refreshAuth(): Promise<any> {
		this.dispose$.next();
		await this.start();
		this.tracker.event(new AuthRefreshEvent());
	}

	private cleanUpAuth0Cookies(): void {
		const cookieJar = this.cookieService.getAll();
		const cookiesToEat = _.pickBy(cookieJar, (cookie, cookieName) => {
			const result = cookieName.includes('a0.spajs.txs');
			return result;
		});
		_.each(cookiesToEat, (cookie, cookieName) =>
			this.cookieService.delete(cookieName, '/')
		);
	}

	private setUpUserContextForLogging(user: any): void {
		if (user) {
			this.tracker.setTrackerContext(
				new ApplicationTrackerContext({
					email: user.email,
					authenticatedUserId: user.sub,
					internalUserId: _.find(user, (value, key) =>
						key.includes('internal_user_id')
					)
				})
			);
			this.isContextSet = true;
		}
	}
}

export interface BasicAuthInfo {
	token: string;
	isLoggedIn: boolean;
	user: any;
}

import { Validatable, Validate, Validators } from '@nbkc/validation';
import { BorrowerDemographicForm } from './borrower-demographics.model';

export class Demographics extends Validatable {
	@Validate(Validators.required())
	public borrowerDemographics: BorrowerDemographicForm[] = [];

	constructor() {
		super();
	}
}

import { DeclarationsApi } from '@models/api/declarations';
import { AdapterBase } from '@utilities/adapter-base';
import { BankruptcyChapters } from '../../constants/bankruptcy-chapters';
import { BorrowerDeclarations2020Form } from '@models/form/declarations/borrower-declarations-2020.model';
import numeral from 'numeral';

export class BorrowerDeclarations2020FormToDeclarationsApiAdapter extends AdapterBase<BorrowerDeclarations2020Form, DeclarationsApi> {
	adapt(source: BorrowerDeclarations2020Form): DeclarationsApi {
		const dest = new DeclarationsApi();
		dest.AlimonyObligation = source.haveAlimonyPayments;
		dest.Bankruptcy = source.bankruptcy;
		dest.ChildSupportObligation = source.haveChildSupportPayments;
		dest.CitizenshipResidencyType = source.citizenship;
		dest.CoMakerEndorserOfNote = source.coSignerOnLoan;
		dest.CountryOfCitizenship = source.countryOfCitizenship;
		dest.OutstandingJudgments = source.outstandingJudgments;
		dest.PartyToLawsuit = source.partyToLawsuit;
		dest.PresentlyDelinquent = source.delinquentOnFederalDebtOrOtherLoan;
		dest.PriorForeclosure = source.foreclosures;
		dest.PriorPropertyDeedInLieuConveyed = source.priorPropertyDeedInLieuConveyed;
		dest.PriorPropertyOwnerPastThreeYears = source.ownershipInterestPastThreeYears;
		dest.PriorPropertyShortSaleCompleted = source.priorPropertyShortSaleCompleted;
		dest.PriorPropertyTitleType = source.priorPropertyTitleType;
		dest.PriorPropertyUsageType = source.priorPropertyUsageType;
		dest.PropertyProposedCleanEnergyLien = source.propertyProposedCleanEnergyLien;
		dest.SpecialBorrowerSellerRelationship = source.specialBorrowerSellerRelationship;
		dest.UndisclosedBorrowedFunds = source.undisclosedBorrowedFunds;
		dest.UndisclosedBorrowedFundsAmount = numeral(source.undisclosedBorrowedFundsAmount).value();
		dest.UndisclosedCreditApplication = source.undisclosedCreditApplication;
		dest.UndisclosedMortgageApplication = source.undisclosedMortgageApplication;

		if (source.bankruptcy) {
			if (source.declaredBankruptcies?.chapter7) {
				dest.BankruptciesDeclared.push(BankruptcyChapters.seven);
			}
			if (source.declaredBankruptcies?.chapter11) {
				dest.BankruptciesDeclared.push(BankruptcyChapters.eleven);
			}
			if (source.declaredBankruptcies?.chapter12) {
				dest.BankruptciesDeclared.push(BankruptcyChapters.twelve);
			}
			if (source.declaredBankruptcies?.chapter13) {
				dest.BankruptciesDeclared.push(BankruptcyChapters.thirteen);
			}
		}

		return dest;
	}
}
export class DocumentRow {
	public DocumentType: string;
	public Filename: string;
	public CreatedDt: Date;
	public ApplicationDocumentStatus: string;
	public downloaded: boolean;
	public downloading: boolean;
	public ApplicationDocumentId: string;
	public ApplicationId: string;
}

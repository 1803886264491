import { Directive, ElementRef, HostListener, OnChanges, SimpleChanges } from '@angular/core';
import numeral from 'numeral';
import { NgModel } from '@angular/forms';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[clearZero]'
})
export class ClearZeroDirective {
	constructor(public el: ElementRef, public ngModel: NgModel) { }

	@HostListener('focus')
	public focus(): void {
		if (numeral(this.el.nativeElement.value).value() === 0) {
			this.ngModel.reset(null);
		}
	}
}

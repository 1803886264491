import { IdDescriptionPair } from './lookups';

export const IncomeTypes: IdDescriptionPair[] = [
	{
		id: 'SocialSecurity',
		description: 'Social Security'
	},
	{
		id: 'Pension',
		description: 'Pension'
	},
	{
		id: 'Other',
		description: 'Other'
	},
	{
		id:'VABenefitsNonEducational',
		description: 'VA Disability'
	},
	{
		id:'ChildSupport',
		description: 'Child Support'
	},
	{
		id:'Alimony',
		description: 'Alimony'
	},
	{
		id:'DefinedContributionPlan',
		description: 'Retirement Distribution'
	}
];

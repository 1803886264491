import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import { BorrowerForm } from '../models/form/borrower.model';
import { LoanForm } from '../models/form/loan.model';
import { AccountForm } from '../models/form/account.model';
import { EmploymentForm } from '@models/form/employment.model';
import { OtherIncomeForm } from '@models/form/other-income.model';
import { IncomeTypes } from '@lookups/income-types';
import { Injectable } from '@angular/core';
import { ApplicationPurposes, ConfigurationValues } from '@constants';
import { convertCollectionToMap } from '@utilities/collection-to-map';
import { AssetTypes } from '@lookups/asset-types';

@Injectable({
	providedIn: 'root'
})
export class GrammarService {
	public incomeTypeMap: Map<string, string>;
	public accountTypeMap: Map<string, string>;

	constructor() {
		this.incomeTypeMap = convertCollectionToMap(IncomeTypes, 'id', 'description');
		this.accountTypeMap = convertCollectionToMap(AssetTypes, 'id', 'description');
	}

	public accountSummary(account: AccountForm, lookups: any): string {
		let amount = numeral(0).format('$0,0.00');

		if (account && account.amount) {
			amount = numeral(account.amount).format('$0,0.00');
		}

		if (account.assetType) {
			return this.accountTitle(account) + ' - ' + amount;
		}
		return amount;
	}

	public borrowerFirstName(borrower: BorrowerForm): string {
		return borrower.firstNameDisplay();
	}

	public borrowerName(borrower: BorrowerForm): string {
		return borrower.nameDisplay();
	}

	public convertUtcToLocal(date: string | Date): moment.Moment {
		return moment.utc(date).local();
	}

	public employmentDates(job: EmploymentForm): string {
		if (job.isCurrent && job.startDate || job.startDate && !job.endDate) {
			return moment(job.startDate, ConfigurationValues.dateFormat).format('MMM YYYY') + ' - Current';
		}

		if (job.startDate && job.endDate) {
			const startDate = moment(job.startDate, ConfigurationValues.dateFormat).format('MMM YYYY');
			const endDate = moment(job.endDate, ConfigurationValues.dateFormat).format('MMM YYYY');
			return startDate + ' - ' + endDate;
		}

		return '';
	}

	public estimatedValueOrPurchasePrice(loan: LoanForm): string {
		if (loan.purpose === ApplicationPurposes.purchase) {
			return 'Purchase Price';
		}
		return 'Estimated Value';
	}

	public getQuestionSubject(borrowers: BorrowerForm[]): string {
		if (borrowers && borrowers.length > 1) {
			let combined = '';

			if (borrowers[0].firstName) {
				combined = borrowers[0].firstName + ' or ';
			} else {
				combined = borrowers[0].emptyName + ' or ';
			}

			if (borrowers[1].firstName) {
				combined += borrowers[1].firstName;
			} else {
				combined += borrowers[1].emptyName;
			}

			return combined;
		}

		return 'you';
	}

	public employmentTitle(employer: EmploymentForm): string {
		if (employer.companyName && employer.jobTitle) {
			return employer.jobTitle + ' at ' + employer.companyName;
		} else if (employer.companyName) {
			return 'Job at ' + employer.companyName;
		} else if (employer.jobTitle) {
			return employer.jobTitle;
		} else {
			return 'New Job';
		}
	}

	public incomeTitle(income: OtherIncomeForm, borrowerIndex: number = 0, borrowers: BorrowerForm[] = []): string | undefined {
		if (borrowers.length > 1) {
			return `${this.borrowerFirstName(borrowers[borrowerIndex])}'s ${this.incomeTypeMap.get(income.type)}`;
		} else if (!!income.type) {
			return `${this.incomeTypeMap.get(income.type)}`
		}
	}

	public accountTitle(account: AccountForm): string | undefined {
		return this.accountTypeMap.get(account.assetType);
	}

	public safeNumber(aNumber: any): number {
		return numeral(aNumber).value();
	}

	public sanitizeCurrency(currencyString: string): string | number {
		if (currencyString && typeof currencyString === 'string') {
			const clean = numeral(currencyString).value();
			return isNaN(clean) ? 0 : clean;
		}
		return currencyString || '0';
	}

}

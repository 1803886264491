import { ContactApi } from '@models/api/contact';
import { ContactForm } from '@models/form/contact-form.model';
import { AdapterBase, AdapterOptions } from '@utilities/adapter-base';
import { DeclarationsProgress } from '@models/form/progress/declarations.progress.model';
import _ from 'lodash';

export class ContactApiToContactFormAdapter extends AdapterBase<ContactApi, ContactForm> {
	public adapt(source: ContactApi, options: CurrentAdapterOptions): ContactForm {
		const dest: ContactForm = new ContactForm();
		if (source) {
			dest.name = options.contactType === 'HazardInsurance' ? source.BusinessName : source.ContactName;
			dest.phone = source.ContactPhone;
			dest.email = source.ContactEmail;
			dest.hasContact = true;
		} else if (_.includes(options.declarationsProgress.answeredContact, options.contactType)) {
			dest.hasContact = false;
		}
		return dest;
	}
}

interface CurrentAdapterOptions extends AdapterOptions {
	contactType: string;
	declarationsProgress: DeclarationsProgress;
}

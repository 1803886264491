import { Validatable, Validate, ValidateIf, Validators } from '@nbkc/validation';
import _ from 'lodash';

export class AddressForm extends Validatable {
	@Validate(Validators.required('Please enter a street address.'))
	public address1: string;
	@Validate(Validators.required('Please enter a city.'))
	public city: string;
	@Validate(Validators.required('Please choose a state.'))
	public state: string;
	public unitType: string;
	@Validate(Validators.maxLength(15, 'Unit number must be less than 16 characters.'))
	public unitNumber: string;
	@Validate(Validators.zip('Please enter a 5 digit zip.'))
	public zip: string;
	@ValidateIf('this.requireCounty === true', Validators.required('Please enter a county.'))
	public county: string;
	public countryCode: string;
	public requireCounty: boolean;

	constructor(params: AddressFormParams = { }) {
		super();
		this.address1 = params.address1;
		this.city = params.city;
		this.state = params.state || '';
		this.unitType = params.unitType;
		this.unitNumber = params.unitNumber;
		this.zip = params.zip;
		this.county = params.county;
		this.countryCode = params.countryCode;
		this.requireCounty = _.isNil(params.requireCounty) ? true : params.requireCounty;
	}

	public get fullAddress(): string {
		const address1 = (this.address1) ? this.address1 + ', ' : '';
		const unitType = (this.unitType) ? this.unitType + ' ' : '';
		const unitNumber = (this.unitNumber) ? this.unitNumber + ', ' : '';
		const city = (this.city) ? this.city + ', ' : '';
		const state = (this.state) ? this.state + ' ' : '';
		const zip = this.zip || '';
		return address1 + unitType + unitNumber + city + state + zip;
	}
}

interface AddressFormParams {
	address1?: string;
	city?: string;
	state?: string;
	unitType?: string;
	unitNumber?: string;
	zip?: string;
	county?: string;
	countryCode?: string;
	requireCounty?: boolean;
}
import { IdDescriptionPair } from './lookups';

export const AssetTypes: IdDescriptionPair[] = [
	{
		id: null,
		description: ''
	},
	{
		id: 'CheckingAccount',
		description: 'Checking Account'
	},
	{
		id: 'SavingsAccount',
		description: 'Savings Account'
	},
	{
		id: 'CertificateOfDeposit',
		description: 'Certificate of Deposit'
	},
	{
		id: 'Gift',
		description: 'Gift'
	},
	{
		id: 'MoneyMarketAccount',
		description: 'Money Market Account'
	},
	{
		id: 'MutualFundAccount',
		description: 'Mutual Fund Account'
	},
	{
		id: 'RetirementAccount',
		description: 'Retirement Account'
	},
	{
		id: 'Stocks',
		description: 'Stocks'
	},
	{
		id: 'Trust',
		description: 'Trust'
	},
	{
		id: 'Other',
		description: 'Other'
	}
];

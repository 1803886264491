import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { UserProfile } from '@models/user-profile.model';
import { AuthService } from '@services/auth.service';
import { ConfigService } from '@services/config.service';
import { ErrorService } from '@services/error.service';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { ProfileApiToUserProfileAdapter } from '../adapters/api-to-form/profile-api-to-user-profile.adapter';
import { UserProfileToProfileApiAdapter } from '../adapters/form-to-api/user-profile-to-profile-api.adapter';
import { Tracker } from '@nbkc/tracker-angular';
import { UserProfileUpdatedEvent } from '../tracking/events';

@Injectable({
	providedIn: 'root'
})
export class UserService {
	private _user: UserProfile;
	private _emptyUser: UserProfile;
	private _userProfileToProfileApiAdapter: UserProfileToProfileApiAdapter = new UserProfileToProfileApiAdapter();
	private _profileApiToUserProfileAdapter: ProfileApiToUserProfileAdapter = new ProfileApiToUserProfileAdapter();

	constructor(
		public http: HttpClient,
		public authService: AuthService,
		public config: ConfigService,
		public injector: Injector,
		public tracker: Tracker
	) {
	}

	public load(): any {
		return new Promise((resolve, reject) => {
			this.authService.getAuthDetails$.subscribe((auth) => {
				if (auth.isLoggedIn) {
					this.getUser().subscribe((response) => {
						if (response.Success) {
							this._user = response.Data ? this._profileApiToUserProfileAdapter.adapt(response.Data) : new UserProfile();
						} else {
							this.setEmptyUser();
							this.handleGetProfileError(response.Data || response);
						}
						resolve(null);
					}, (response) => {
						this.setEmptyUser();
						this.handleGetProfileError(response);
						resolve(null);
					});
				} else {
					this.setEmptyUser();
					resolve(null);
				}
			});
		});
	}

	public get current(): UserProfile {
		return this._user || this._emptyUser;
	}

	public set current(value: UserProfile) {
		this._user = value;
	}

	public get hasProfile(): boolean {
		return !!this._user;
	}

	public getUser(): Observable<any> {
		return this.http.get(this.config.MTG_API_DOMAIN + '/api/user/profile').pipe(first());
	}

	public get isProfileComplete(): boolean {
		return this.authService.loggedIn && this.checkProfileCompleteness(this.current);
	}

	public get isLoanOfficer(): boolean {
		return this.current && _.some(
			['loan-officer', 'loan-officer-administrator', 'loan-officer-manager'],
			(role) => _.includes(this.current.Roles, role)
		);
	}

	public get isBorrower(): boolean {
		return this.current && !_.some(
			['loan-officer', 'loan-officer-administrator', 'loan-officer-manager'],
			(role) => _.includes(this.current.Roles, role)
		);
	}

	public checkProfileCompleteness(user: UserProfile): boolean {
		return user && user.isValid().valid;
	}

	public getUserById(userId: string): Observable<any> {
		return this.http.get(this.config.MTG_API_DOMAIN + '/api/user/profile?userId=' + userId).pipe(first());
	}

	public handleGetProfileError(response: any): void {
		const errorService = this.injector.get(ErrorService);
		errorService.handle({
			data: response,
			display: 'error-page',
			message: 'Cannot load your user profile. Use the button below to logout, and then login again to retry.',
			retryAction: () => {
				this.authService.logout();
			},
			retryText: 'Logout'
		});
	}

	public saveProfile(): Observable<void> {
		return this.http
			.post<any>(
				this.config.MTG_API_DOMAIN + '/api/user/profile',
				this._userProfileToProfileApiAdapter.adapt(this._user)
			)
			.pipe(
				tap(() => {
					this.tracker.event(new UserProfileUpdatedEvent());
				}),
				first()
			);
	}

	private setEmptyUser(): void {
		this._emptyUser = new UserProfile();
		this._emptyUser.FirstName = '';
		this._emptyUser.LastName = '';
		this._emptyUser.Roles = [];
	}
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Tracker } from '@nbkc/tracker-angular';
import { AuthService } from '@services/auth.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthGuardSuccessEvent } from './shared/tracking/events/auth-guard-success.event';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {
	constructor(private auth: AuthService, private tracker: Tracker) { }

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean | UrlTree> {
		return this.auth.getAuthDetails$.pipe(
			tap((auth) => {
				if (!auth.isLoggedIn) {
					this.auth.login(state.url);
				} else {
					this.tracker.event(new AuthGuardSuccessEvent());
				}
			}),
			map((auth) => auth.isLoggedIn)
		);
	}
}

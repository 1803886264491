export class SubjectPropertyApi {
	public PropertyType: string | null;
	public StructureType: string | null;
	public StateAbbreviation: string | null;
	public EstimatedAppraisalFee: number | null;
	public EstimatedValue: number | null;
	public Address1: string | null;
	public Address2: string | null;
	public City: string | null;
	public PostalCode: string | null;
	public UnitType: string | null;
	public UnitNumber: string | null;
	public County: string | null;
	public CountryCode: string | null;
	public FirstLienBalance: number | null;
	public SecondLienBalance: number | null;
}

<app-app-header></app-app-header>
<div class="error-container">
  <div class="error-text">
    <h1 class="error-header">{{ title }}</h1>
    <p class="error-body">{{ message }}</p>
  </div>
  <div class="error-retry" *ngIf="retryAction">
    <button mat-raised-button color="primary" class="blue-material-button" (click)="retryAction()">{{ retryText }}</button>
  </div>
  <div class="debug-details" *ngIf="config.DEBUG === 'true' && debugDetails">
    <div class="debug-header">Error Details:</div>
    <pre>{{debugDetailsFormatted}}</pre>
  </div>
</div>

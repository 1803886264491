import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Tracker } from '@nbkc/tracker-angular';
import { AuthService } from '@services/auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GeneralError } from './shared/tracking/errors/general.error';
import { HttpError } from './shared/tracking/errors/http.error';

@Injectable({
	providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
	constructor(public tracker: Tracker, private auth: AuthService) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			catchError((event) => {
				if (event instanceof HttpErrorResponse) {
					try {
						this.tracker.error(new HttpError({ error: event }));
						if (event.status === 401) {
							this.auth.refreshAuth();
						}
					} catch (error) {
						this.tracker.error(new GeneralError(error));
					}
				}
				return throwError(event);
			})
		);
	}
}

import { AdapterBase } from '@utilities/adapter-base';
import { BorrowerRequestApi } from '@models/api/borrower-request';
import { RequiredDocument } from '@models/required-document.model';
import { FulfillmentDocumentApiToUploadedDocumentAdapter } from './fulfillment-document-api-to-uploaded-document.adapter';

export class BorrowerRequestApiToRequiredDocumentAdapter extends AdapterBase<BorrowerRequestApi, RequiredDocument> {
	// eslint-disable-next-line max-len
	private _fulfillmentDocumentApiToUploadedDocumentAdapter: FulfillmentDocumentApiToUploadedDocumentAdapter = new FulfillmentDocumentApiToUploadedDocumentAdapter();

	public adapt(source: BorrowerRequestApi): RequiredDocument {
		const requiredDocument = new RequiredDocument();
		requiredDocument.description = source.Description;
		requiredDocument.name = source.Name;
		requiredDocument.applicationRequiredDocumentId = source.BorrowerRequestId;
		requiredDocument.uploadedDocuments = this._fulfillmentDocumentApiToUploadedDocumentAdapter.adaptCollection(
			source.FulfillmentDocuments
		);
		return requiredDocument;
	}
}

import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { first, map, retry } from 'rxjs/operators';
import { ConfigService } from '@services/config.service';

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	private baseUrl: string = this.config.MTG_API_DOMAIN;

	constructor(private http: HttpClient, private config: ConfigService) {
	}

	public get<TResponseData>(action: string): Observable<TResponseData> {
		return this.http.get<TResponseData>(this.baseUrl + '/api/' + action).pipe(retry(3), first());
	}

	public post<TRequestData, TResponseData>(action: string, data: TRequestData): Observable<TResponseData> {
		return this.http.post<TResponseData>(this.baseUrl + '/api/' + action, data).pipe(retry(3), first());
	}

	public patch<TRequestData, TResponseData>(action: string, data: TRequestData): Observable<TResponseData> {
		return this.http.patch<TResponseData>(this.baseUrl + '/api/' + action, data).pipe(retry(3), first());
	}

	public upload<TRequestData, TResponseData>(action: string, data: TRequestData): Observable<HttpEvent<any>> {
		return this.http.post(`${this.baseUrl}/api/${action}`, data, {
			reportProgress: true,
			observe: 'events'
		});
	}
}

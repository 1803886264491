import { ElementRef } from '@angular/core';
import { ApplicationTrackerContext } from '../application-tracker-context';
import {
	TrackerEvent,
	ApplicationInsightsProviderMapping,
	GoogleAnalyticsProviderMapping,
	ApplicationInsightsEvent,
	GoogleAnalyticsEvent
} from '@nbkc/tracker';

export class ClickEvent extends TrackerEvent<ApplicationTrackerContext>
	implements ApplicationInsightsProviderMapping, GoogleAnalyticsProviderMapping {
	public name: string = 'click';

	public constructor(public data: ClickEventProps) {
		super();
	}

	public ai(): ApplicationInsightsEvent {
		const aiEvent = new ApplicationInsightsEvent();
		aiEvent.name = 'click';
		aiEvent.data = {
			elementId: this.data.targetElement.nativeElement.id
		};
		return aiEvent;
	}

	public ga(): GoogleAnalyticsEvent {
		const gaEvent = new GoogleAnalyticsEvent();
		gaEvent.category = 'Application';
		gaEvent.action = 'Click';
		gaEvent.label = this.data.targetElement.nativeElement.id;
		return gaEvent;
	}
}

export interface ClickEventProps {
	targetElement: ElementRef;
}

<div [ngClass]="{'expanded': portalState.preApprovalFormShown === true }">
    <div class="pre-approval-form-body">
        <div class="row">
            <div class="col-sm-12 loan-app-form-control helper-text-wrapper">
                <label id="salesPrice" for="salesPriceInput">Sales Price</label>
                <input id="salesPriceInput" class="form-control" placeholder="$0" class="form-control"
                    [textMask]="lookups.TextMasks.currency" inputmode="numeric"
                    [(ngModel)]="portfolioService.preApprovalLetter.salesPrice"
                    [autoValidate]="portfolioService.preApprovalLetter" autoValidatePropertyKey="salesPrice" clearZero
                    aria-label="Sales Price" (input)="getCalculatedValues()" />
                <span class="helper-text">This is the total purchase price of the home.</span>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 loan-app-form-control">
                <label id="loanToValue" for="loanToValueInput">Loan-to-Value</label>
                <input id="loanToValueInput" disabled placeholder="0%" class="form-control"
                [textMask]="lookups.TextMasks.percentNoDecimalThreeDigit" inputmode="numeric"
                [(ngModel)]="portfolioService.preApprovalLetter.loanToValue"
                    [autoValidate]="portfolioService.preApprovalLetter" autoValidatePropertyKey="loanToValue"
                    aria-label="Loan to value percent" />
            </div>
            <div class="col-sm-6 loan-app-form-control helper-text-wrapper">
                <label id="loanAmount" for="loanAmountInput">Loan Amount</label>
                <input id="loanAmountInput" name="loanAmount" disabled placeholder="$0" class="form-control"
                    [textMask]="lookups.TextMasks.currency" inputmode="numeric"
                    [(ngModel)]="portfolioService.preApprovalLetter.loanAmount"
                    [autoValidate]="portfolioService.preApprovalLetter" autoValidatePropertyKey="loanAmount"
                    aria-label="Loan Amount" />
                <span class="helper-text">This is the total amount you will be borrowing from the bank.</span>
            </div>
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, mergeMap, tap } from 'rxjs/operators';
import { AuthService } from '@services/auth.service';

@Injectable({
	providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
	constructor(private auth: AuthService) {
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return this.auth.getAuthDetails$.pipe(
			tap((auth) => {
				if (!auth.isLoggedIn) {
					this.auth.login();
				}
			}),
			filter((auth) => typeof auth.token === 'string'),
			mergeMap((auth) => {
				if (!req.url.includes('stpublicplatform')) {
					const tokenReq = req.clone({
						setHeaders: { Authorization: `Bearer ${auth.token}` }
					});
					return next.handle(tokenReq);
				}
				return next.handle(req);
			}),
			catchError((err) => throwError(err))
		);
	}
}

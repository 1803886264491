import { HttpResponse } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import { Document } from "@models/api/borrower-portal/document";
import { Tracker } from "@nbkc/tracker-angular";
import { PortfolioService } from "@services/borrower-portal/portfolio.service";
import { CardService } from "@services/card.service";
import { ConfigService } from "@services/config.service";
import _ from "lodash";
import { DocumentUploadedEvent } from "src/app/shared/tracking/events/document-uploaded.event";
import { FailedFile } from "./models/failed-file.model";
import { FileBatch } from "./models/file-batch.model";
import { PortfolioRequestForm } from "@models/form/borrower-portal/portfolio-request-form.model";

@Component({
	selector: 'app-document-upload',
	templateUrl: './document-upload.component.html',
	styleUrls: ['./document-upload.component.scss'],
})
export class DocumentUploadComponent implements OnInit {
	@Input() documentUploadRequest: PortfolioRequestForm;

	private fileSizeLimit: number = 25 * 1000 * 1000;
	private maxDocumentsDisplayed: number = 4;
	public isUploadLimitExceeded: boolean = false;
	public totalUploadCount: number = 0;
	public showFailedFiles: boolean = false;

	public fileBatches: FileBatch[] = [];
	public failedFiles: FailedFile[] = [];
	public completedFiles: number;

	constructor(
		public portfolioService: PortfolioService,
		public tracker: Tracker,
		public cardService: CardService,
		public config: ConfigService
	) { }

	ngOnInit() {
		this.totalUploadCount = this.documentUploadRequest?.detail?.Documents.length ?? 0;
	}

	public onProgressBarComplete(batch: FileBatch) {
		this.totalUploadCount += batch.Files.filter(x => x.Completed && !x.HasError).length;
		this.fileBatches.splice(this.fileBatches.indexOf(batch), 1);
		this.showFailedFiles = true;
	}

	public dismissItem(file: FailedFile): void {
		_.remove(this.failedFiles, file);
	}

	public newFileUpload(files: FileList) {
		let uploadLimit = Number(this.config.DOCUMENT_UPLOAD_LIMIT);

		if (uploadLimit && this.totalUploadCount + files.length >= uploadLimit) {
			this.isUploadLimitExceeded = true;
			return;
		}
		this.uploadFiles(files);
	}

	public uploadFiles(files: FileList): void {
		this.failedFiles = [];
		let isBulkUpload = files.length > this.maxDocumentsDisplayed;

		if (isBulkUpload) {
			this.fileBatches.push(this.getBulkUploadBatch(files));
		} else {
			_.each(files, (file) => {
				let newBatch = new FileBatch();
				newBatch.DisplayName = file.name;
				newBatch.Files = [this.createFileUploadItem(file)];
				newBatch.HasError = !!newBatch.Files.find(x => x.HasError);
				this.fileBatches.push(newBatch);
			});
		}

		this.processBatches();
	}

	private processBatches(): void {
		let batches = this.fileBatches.filter(
			fileBatch => fileBatch.IsComplete === false && !fileBatch.HasError
		);

		batches.forEach(batch => {
			let failedFiles = [];

			batch.Files.forEach(value => {
				this.portfolioService.satisfyDocumentUploadRequest(value.FormData, this.documentUploadRequest.id).subscribe((event) => {
					if (!(event instanceof HttpResponse)) {
						return;
					}

					if (event.body.Message) {
						batch.HasError = true;
						value.HasError = true;
						value.ErrorMessage = value.Filename;

						failedFiles.push({
							displayName: value.Filename,
							errorMessage: event.body.Message
						});
					} else {
						value.Completed = true;
						this.tracker.event(new DocumentUploadedEvent({ documentName: value.Filename }));
					}

					if (!batch.Files.find(x => x.Completed === false && !x.HasError)) {
						batch.IsComplete = true;
						if (failedFiles.length === 1) {
							this.failedFiles.push({
								displayName: failedFiles[0].displayName,
								errorMessage: failedFiles[0].errorMessage
							}); 
						} 

						if (failedFiles.length > 1) {
							this.failedFiles.push({
								displayName: failedFiles.length.toString(),
								errorMessage: 'Documents failed to upload'
							}); 
						}
					}
				});
			})
		});
	}

	private createFileUploadItem(file): Document {
		const fileUpload = new Document();
		fileUpload.Filename = file.name;
		fileUpload.FormData.append('file', file);
		fileUpload.Completed = false;

		if (file.size > this.fileSizeLimit) {
			fileUpload.HasError = true;

			this.failedFiles.push({
				displayName: file.Filename,
				errorMessage: 'File is too large'
			});
		}

		return fileUpload;
	}

	private getBulkUploadBatch(files: FileList) {
		let bulkBatch = new FileBatch();
		bulkBatch.DisplayName = files.length + ' documents';
		bulkBatch.Files = [];

		_.each(files, (file) => {
			bulkBatch.Files.push(this.createFileUploadItem(file));
		});

		bulkBatch.HasError = !!bulkBatch.Files.find(x => x.HasError);
		return bulkBatch;
	}
}
<lib-card-component data-cy="document-upload-card-component" [headerIcon]="documentUploadRequest.icon"
					[headerTitle]="documentUploadRequest.displayName" [contentBody]="documentUploadRequest.description">
    <div card-content class="uploaded-files-container">
        <div *ngFor="let fileBatch of fileBatches; index as i">
            <div class="file-container">
                <mat-icon *ngIf="fileBatch.IsComplete && !fileBatch.HasError" fontSet="fal" fontIcon="fa-check-circle"></mat-icon>
                <mat-icon *ngIf="!fileBatch.IsComplete && !fileBatch.HasError" fontSet="fal" fontIcon="fa-file-alt"></mat-icon>
                <mat-icon *ngIf="fileBatch.HasError" fontSet="fal" fontIcon="fa-exclamation-circle" class="file-status-icon-error"></mat-icon>
                <div class="file-name">
                    {{fileBatch.DisplayName}}
                </div>
            </div>
            <app-document-upload-progress-bar
                [(fileBatch)]="fileBatches[i]"
                (progressComplete)="onProgressBarComplete($event)" >
            </app-document-upload-progress-bar>
        </div>
        <ng-container *ngFor="let failedFile of failedFiles">
            <div *ngIf="showFailedFiles" class="progress-error">
                <mat-icon fontSet="fal" fontIcon="fa-exclamation-circle" class="file-status-icon-error"></mat-icon>
                <span>{{failedFile.displayName}} {{failedFile.errorMessage}}</span>
                <mat-icon fontSet="fal" (click)="dismissItem(failedFile)" fontIcon="fa-times dismiss-icon" class="file-status-icon-error dismiss-icon"></mat-icon>
            </div>
        </ng-container>
        <div *ngIf="isUploadLimitExceeded" class="error">
            <mat-icon fontSet="fal" fontIcon="fa-exclamation-triangle"></mat-icon>
            You attempted to uploaded more than the maximum number of documents allowed. Please try
            again or
            contact your
            loan officer to provide more documents.
        </div>
        <div *ngIf="totalUploadCount > 0" class="overflow-files-text">
            <mat-icon fontSet="fal" fontIcon="fa-cloud-upload"></mat-icon>
            {{totalUploadCount}} total {{totalUploadCount === 1 ? 'file' : 'files'}} uploaded successfully
        </div>
    </div>
    <lib-button card-footer-button class="material-button-no-padding"
        [id]="'lib-right-action'" [displayText]="'Upload Documents'"
        (click)="isUploadLimitExceeded ? '' : uploadInput.click()"
        [isDisabled]="isUploadLimitExceeded">
    </lib-button>
</lib-card-component>
<input type="file" (change)="newFileUpload($event.target.files); uploadInput.value = null" [hidden]="true" multiple
#uploadInput>
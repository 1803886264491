import _ from 'lodash';
import { Sections } from '@lookups/sections';
import { Section } from '@models/section.model';
import { ApplicationService } from './application.service';
import { SectionProgress } from '@models/form/progress/section.progress.model';
import { ConversationCardSectionProgress } from '@models/form/progress/conversation-card-section.progress.model';
import { QualificationResult } from '@models/form/qualification-result.model';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApplicationPurposes, SectionNames } from '@constants';
import { Tracker } from '@nbkc/tracker-angular';
import { ApplicationTrackerContext } from '../tracking/application-tracker-context';

@Injectable({
	providedIn: 'root'
})
export class SectionService {
	public appSections: Section[];
	public navSections: Section[];

	public current: Section = null;

	constructor(public route: ActivatedRoute, public applicationService: ApplicationService, public tracker: Tracker) {
		this.applicationService.subscribeToBusinessRules((rules: QualificationResult[]) => {
			this.buildSection(rules);
		});
		this.buildSection([]);
		this.updateNavSections();
	}

	public get incompleteSections(): Section[] {
		return _.filter(this.appSections, (x) => this.isNotComplete(x.name));
	}

	public get next(): Section {
		const allSections = this.appSections;
		const current = this.current;

		let passedCurrent = false;

		const nextIndex = _.findIndex(allSections, (section) => {
			if (section === current) {
				passedCurrent = true;
			} else if (passedCurrent && !section.hiddenFromNavigation && !section.disabled) {
				return true;
			}
			return false;
		});

		if (nextIndex > -1) {
			return allSections[nextIndex];
		}

		return current;
	}

	public setAvailableSections(){
		switch(this.applicationService.currentApplication.loan.purpose){
			case ApplicationPurposes.purchase: {
				this.enableSection(SectionNames.otherIncome);
				this.enableSection(SectionNames.declarations);
				this.enableSection(SectionNames.demographics);
				this.enableSection(SectionNames.realEstate);
				this.enableSection(SectionNames.residenceHistory);

				this.disableSection(SectionNames.assets);
				break;
			}
			case ApplicationPurposes.heloc: {
				this.enableSection(SectionNames.otherIncome);

				this.disableSection(SectionNames.assets);
				this.disableSection(SectionNames.declarations);
				this.disableSection(SectionNames.demographics);
				this.disableSection(SectionNames.realEstate);
				this.disableSection(SectionNames.residenceHistory);
				break;
			}
			case ApplicationPurposes.refinance: {
				this.enableSection(SectionNames.declarations);
				this.enableSection(SectionNames.demographics);
				this.enableSection(SectionNames.realEstate);
				this.enableSection(SectionNames.residenceHistory);

				this.disableSection(SectionNames.otherIncome);
				this.disableSection(SectionNames.assets);
				break;
			}
			default: {
				
				this.disableSection(SectionNames.assets);
			}
		}
	}

	public disableSection(sectionName: string): void {
		const section = this.getSection(sectionName);
		if (section) {
			section.disabled = true;
		}
		this.updateNavSections();
	}

	public enableSection(sectionName: string): void {
		const section = this.getSection(sectionName);
		if (section) {
			section.disabled = false;
		}
		this.updateNavSections();
	}

	public updateNavSections(): void {
		this.navSections = _.filter(this.appSections, (section) => !section.hiddenFromNavigation && !section.disabled);
	}

	public setCurrentSection(sectionName: string): void {
		this.tracker.setTrackerContext(new ApplicationTrackerContext({
			sectionName
		}));
		this.current = _.find(this.appSections, (section) => section.name === sectionName) || new Section();
	}

	public getSection(sectionName: string): Section | undefined {
		return _.find(this.appSections, (section) => section.name === sectionName);
	}

	public isComplete(sectionName: string): boolean {
		if (sectionName) {
			const section: Section | undefined = this.getSection(sectionName);
			if (section) {
				const complete = this.isValid(sectionName) && this.isVisited(sectionName);
				section.progress.complete = complete;
				return complete;
			}
		}
		return false;
	}

	public isVisited(sectionName: string): boolean {
		if (sectionName) {
			const section: Section | undefined = this.getSection(sectionName);
			if (section) {
				return section.progress.visited;
			}
		}
		return false;
	}

	public isValid(sectionName: string): boolean {
		if (sectionName) {
			const section: Section | undefined = this.getSection(sectionName);
			if (section) {
				let answeredConversationQuestion = true;
				const sectionProgress: ConversationCardSectionProgress = section.progress as ConversationCardSectionProgress;
				if (sectionProgress && sectionProgress.answeredQuestion === null) {
					answeredConversationQuestion = false;
				}
				return this.applicationService.currentApplication.isValid(section.modelName).valid && answeredConversationQuestion;
			}
		}
		return false;
	}

	public isCurrentSection(sectionName: string): boolean {
		if (sectionName && this.current) {
			return sectionName === this.current.name;
		}
		return false;
	}

	public isNotComplete(sectionName: string): boolean {
		const section = this.getSection(sectionName);
		return !(
			this.isComplete(sectionName) ||
			(section && (section.hiddenFromReviewPage || section.hiddenFromNavigation || section.disabled))
		);
	}

	public getSectionRules(sectionName: string): QualificationResult[] | null {
		const mySection = this.getSection(sectionName);
		return (mySection) ? mySection.businessRules : null;
	}

	public buildSection(rules: QualificationResult[]): void {
		this.appSections = _.map(Sections, (section) => {
			if (
				this.applicationService &&
				this.applicationService.currentApplication &&
				this.applicationService.currentApplication.progress &&
				this.applicationService.currentApplication.progress.sections
			) {
				if (!this.applicationService.currentApplication.progress.sections[section.name]) {
					this.applicationService.currentApplication.progress.sections[section.name] = new SectionProgress();
				}
				section.progress = this.applicationService.currentApplication.progress.sections[section.name];
			}
			if (!section.progress) {
				section.progress = new SectionProgress();
			}
			section.businessRules = _.filter(rules, (rule: QualificationResult) => {
				return _.includes(rule.tags, section.name) && !rule.passed;
			});

			return section;
		});
	}

	public reset(): void {
		this.current = null;
	}
}

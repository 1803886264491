import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Route, Router } from '@angular/router';
import { Tracker } from '@nbkc/tracker-angular';
import { AppState } from '@services/app-state.service';
import { ApplicationService } from '@services/application.service';
import { CompatibilityFixes } from '@utilities/compatibility/compatibility-fixes';
import _ from 'lodash';
import { PageViewEvent, ViewSectionEvent } from 'src/app/shared/tracking/events';
import { ApplicationTrackerContext } from './shared/tracking/application-tracker-context';
import { ConfigService } from '@services/config.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	public dismissOldBrowser: boolean = false;
	public isOldBrowser: boolean = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		public appState: AppState,
		public tracker: Tracker,
		public config: ConfigService) {
	}

	ngOnInit(): void {

		this.isOldBrowser = CompatibilityFixes.isOldBrowser();
		let anonPath = location.pathname;
		const urlGuidRegex = /(?:.*)([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})(?:.*)$/;
		let urlGuidMatch = urlGuidRegex.exec(location.pathname);

		if (urlGuidMatch && urlGuidMatch.length > 1) {
			anonPath = anonPath.replace(urlGuidMatch[1], ':id');
		}

		this.tracker.event(new ViewSectionEvent());
		this.tracker.pageView(new PageViewEvent());

		this.router.events.subscribe((event) => {
			urlGuidMatch = urlGuidRegex.exec(location.pathname);
			if (urlGuidMatch && urlGuidMatch.length > 1) {
				anonPath = location.pathname.replace(urlGuidMatch[1], ':id');
			} else {
				anonPath = location.pathname;
			}
			if (event instanceof NavigationStart) {
				this.tracker.setTrackerContext(new ApplicationTrackerContext({
					page: anonPath,
					sectionName: this.climbThatTree(this.route) || 'site-root-or-loader'
				}));
			}

			if (event instanceof NavigationEnd) {
				this.tracker.setTrackerContext(new ApplicationTrackerContext({
					page: anonPath,
					sectionName: this.climbThatTree(this.route) || 'site-root-or-loader'
				}));
				this.tracker.event(new ViewSectionEvent());
				this.tracker.pageView(new PageViewEvent());
			}
		});

		if (this.config.ENVIRONMENT == "Production") {
			let script = document.createElement('script');
			script.type = 'text/javascript';
			script.src = 'https://js.authorize.net/v1/Accept.js';
			document.head.appendChild(script);
		} else {
			let script = document.createElement('script');
			script.type = 'text/javascript';
			script.src = 'https://jstest.authorize.net/v1/Accept.js';
			document.head.appendChild(script);
		}
	}

	private climbThatTree(route: ActivatedRoute, builder: string = null): any {
		if (route && route.children && route.children.length > 0) {
			if (route.snapshot.routeConfig) {
				return this.climbThatTree(route.children[0], this.buildPath(builder, route.snapshot.routeConfig));
			} else {
				return this.climbThatTree(route.children[0], builder);
			}
		} else {
			return this.buildPath(builder, route.snapshot.routeConfig);
		}
	}

	private buildPath(existingString: string, route: Route): string {
		if (route && route.path.includes('/')) {
			const split = route.path.split('/');
			existingString = _.filter(split, (x) => !x.includes(':')).join('.');
		}
		if (route && !route.path.includes(':')) {
			if (existingString) {
				return `${existingString}.${route.path}`;
			}
			return route.path;
		}
		return existingString;
	}
}

import {
	TrackerEvent,
	ApplicationInsightsProviderMapping,
	GoogleAnalyticsProviderMapping,
	ApplicationInsightsEvent,
	GoogleAnalyticsEvent
} from '@nbkc/tracker';
import { ApplicationTrackerContext } from '../application-tracker-context';

export class LoanApplicationStartedEvent extends TrackerEvent<ApplicationTrackerContext>
	implements ApplicationInsightsProviderMapping, GoogleAnalyticsProviderMapping {
	public name: string = 'application-started';

	public ai(): ApplicationInsightsEvent {
		const event = new ApplicationInsightsEvent();
		event.name = this.name;
		return event;
	}

	public ga(): GoogleAnalyticsEvent {
		return new GoogleAnalyticsEvent({
			category: 'Applications List',
			action: 'Click',
			label: 'Start New'
		});
	}
}

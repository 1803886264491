import {
	TrackerEvent,
	ApplicationInsightsProviderMapping,
	GoogleAnalyticsProviderMapping,
	ApplicationInsightsEvent,
	GoogleAnalyticsEvent
} from '@nbkc/tracker';
import { ApplicationTrackerContext } from '../application-tracker-context';

export class LoCardNMLSEvent extends TrackerEvent<ApplicationTrackerContext>
	implements ApplicationInsightsProviderMapping, GoogleAnalyticsProviderMapping {
	public name: string = 'lo-card-action';

	public constructor(public data: LoCardNMLSEventProps) {
		super();
	}

	public ai(): ApplicationInsightsEvent {
		const event = new ApplicationInsightsEvent();
		event.name = this.name;
		event.data = {
			actionType: 'nmls',
			loEmail: this.data.loEmail
		};
		return event;
	}

	public ga(): GoogleAnalyticsEvent {
		return new GoogleAnalyticsEvent({
			category: 'Application',
			action: 'Click',
			label: 'LO Card - NMLS'
		});
	}
}

interface LoCardNMLSEventProps {
	loEmail: string;
}

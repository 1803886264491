import { UploadedDocument } from './uploaded-document.model';

export class RequiredDocument {
	public applicationRequiredDocumentId: string | null;
	public description: string | null;
	public name: string | null;
	public documentType: string;
	public documentTypeId: string | null;
	public uploadedDocuments: UploadedDocument[];
	public currentUploads: any;

	constructor() {
		this.documentType = '';
		this.applicationRequiredDocumentId = null;
		this.documentTypeId = null;
		this.description = null;
		this.name = null;
		this.uploadedDocuments = [];
	}
}

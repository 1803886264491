import { formatDate } from "@angular/common";
import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Package } from "@models/api/borrower-portal/package";
import 'moment-timezone';
import moment from "moment";
import { EsignPackageStatus } from "src/app/shared/constants/borrower-portal/esign-package-status";
import { Tracker } from "@nbkc/tracker-angular";
import { ESignViewPackageEvent } from "src/app/shared/tracking/events/e-sign-view-package.event";

@Component({
	selector: 'app-e-sign',
	templateUrl: './e-sign.component.html',
	styleUrls: ['./e-sign.component.scss'],
})
export class ESignComponent implements OnInit, OnChanges {  
    @Input() eSignPackages: Package[];
  
      public incompletePackages: Package[] = [];
      public completedPackages: Package[] = [];
      public completedPackagesCount: number = 0;
      public inCompletedPackagesCount: number = 0;
      public showCompleted: boolean = false;

      constructor(public tracker: Tracker){}

      ngOnInit(){
        this.assignPackageListsByStatusAndCounts(this.eSignPackages);
      }

      ngOnChanges(changes: SimpleChanges): void {
        this.assignPackageListsByStatusAndCounts(changes.eSignPackages.currentValue);
      }

      assignPackageListsByStatusAndCounts(packages: Package[]){
          this.incompletePackages = packages.filter(p => p.Status === EsignPackageStatus.inProgress);
          this.completedPackages = packages.filter(p => p.Status != EsignPackageStatus.inProgress);
          this.completedPackagesCount = packages.filter(p => p.Status != EsignPackageStatus.inProgress).length;
          this.inCompletedPackagesCount =  packages.filter(p => p.Status === EsignPackageStatus.inProgress).length;
      }

      public goToLink(url: string, packageId: string, borrowerName: string, packageName: string){
        this.tracker.event(new ESignViewPackageEvent(
          {
            portfolioId: packageId ?? "unavailable",
            borrowerName: borrowerName ?? "unavailable",
            packageName: packageName ?? "unavailable"
          }
        ));
        window.open(url, "_blank");
      }

      getFormatedDate(date: string):string{
        const convertedDate = new Date(date);
        const amPmString = this.formatAMPM(convertedDate);
        return formatDate(convertedDate,'MM/dd/yyyy ','en-US') + amPmString;
      }

      formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var localTimeZone = moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z');
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ampm + ' ' + localTimeZone;
        return strTime;
      }

}